import axiosFetch from "axios";
import axios from "../AxiosConfig/axios";

import store from "../Redux/store";
import {
  encodeData,
  headerVersion2,
  returnLocationId,
  returnUserId,
} from "../../src/components/common/Utils/utils";
import { serverRoutes } from "./../constants/serverRoutes";
import genericAxiosCall from "../AxiosConfig/genericAxiosCall";

const subId = localStorage.getItem("subscriptionID");

const common = {
  // getting all cptCodes
  getCPTCodes(params) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_CPT_LIST}${encodeData(params)}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getSSOConfig({ email, domain }) {
    return axios.get(
      `${serverRoutes?.GET_USER_INFO}?domainName=${domain}&email=${email}`,
      {
        ["X-Version"]: "1.0",
        // ["X-Domain"]: "8700a71f-b5f5-43a5-be17-492c67008132",
        ["X-Domain"]: "",
      }
    );
  },

  // getting all jcodes
  getAllJCodes(params) {
    return axios.get(`${serverRoutes?.GET_JCODE_LIST}${encodeData(params)}`, {
      headers: { "X-Subscription": subId },
    });
  },

  // get ClaimStatus list for Payment(835)
  getClaimStatusForClaimRequest(params) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_CLAIM_STATUS_LIST}${encodeData(params)}`
    );
  },

  // get all payers list for Manager(837)
  getPayersForClaimRequest(params) {
    return axios.get(
      `${serverRoutes?.GET_CLAIM_REQUEST_PAYERS_LIST}${encodeData(params)}`
    );
  },

  // get all providers list for Manager(837)
  getProvidersForClaimRequest(params) {
    return axios.get(
      `${serverRoutes?.GET_CLAIM_REQUEST_PROVIDERS_LIST}${encodeData(params)}`
    );
  },

  // get CPTCodes list for Manager(837)
  getCPTCodesForClaimRequest(params) {
    return axios.get(
      `${serverRoutes?.GET_CLAIM_REQUEST_CPT_LIST}${encodeData(params)}`
    );
  },
  SmartAppealCreditCheck() {
    const params = { userId: returnUserId() };
    return genericAxiosCall(
      serverRoutes?.SMART_APPEALS_CREDIT_CHECK,
      "get",
      "",
      params
    );
  },

  // get DiagnosisCodes list for Manager(837)
  getDiagnosisCodesForClaimRequest(params) {
    return axios.get(
      `${serverRoutes?.GET_CLAIM_REQUEST_DIAGNOSIS_LIST}${encodeData(params)}`
    );
  },
  AuthenticateEmailSSO(email) {
    const params = { email: email };
    return genericAxiosCall(
      serverRoutes?.AUTHENTICATE_WITH_SSO,
      "get",
      "",
      params
    );
  },
  // get all payers list for Payment(835)
  getPayersForClaimPayments(params) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_PAYERS_LIST}${encodeData(params)}`
    );
  },

  // get all reason codes
  getReasonCodes() {
    return axios.get(`/Report/GetReasonCode`, {
      headers: { "X-Subscription": subId },
    });
  },

  // get CARC for claimPayment (835) screem
  getReasonCodesForClaimPayments(params) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_CARC_LIST}${encodeData(params)}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },

  cities() {
    return axios.get(serverRoutes?.GET_CITIES);
  },
  citiesByState(body) {
    return axios.get(`/city/${body.country}/${body.state}`);
  },
  countries() {
    return axios.get(serverRoutes?.GET_COUNTRY);
  },
  states() {
    return axios.get(serverRoutes?.GET_STATES);
  },
  getSubscriptionsRoles() {
    return axios.get(`/role`);
  },
  getSubscriptionPlans() {
    return axios.get(serverRoutes?.GET_ALL_SUBSCRIBED_MODULES);
  },
  getActiveSubscription() {
    return axios.get("/subscription/1/0/100");
  },
  getClaimCounts() {
    return axios.get("/Analysis/ClaimCountByYear?Year=2020");
  },
  getMonthlyClaimCounts() {
    return axios.get("/Analysis/ClaimCountByMonth?Month=November&Year=2020");
  },
  getSingleSubscription() {
    const domainId = store.getState().pReducers.user.domainID;

    return axios.get(
      `/Customer/SubscriptionInfoByDomainId?DomainId=${domainId}`
    );
  },
  getClaimsPayments(params) {
    return axios.get(`${serverRoutes?.GET_PAYMENT_835}${encodeData(params)}`);
  },
  getClaimPaymentStatus() {
    return axios.get(`/claimpayment/ClaimPaymentStatus`);
  },
  getClaimDetail(id) {
    const timestamp = new Date().getTime();
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_DETAIL_835}${id}?timestamp=${timestamp}`
    );
  },
  getServicePayments(id) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_DETAIL_SERVICE_PAYMENT_835}${id}`
    );
  },
  getServicePaymentsFotRaDet(id, start, limit, order, orderBy) {
    const url = `${serverRoutes?.GET_PAYMENT_DETAIL_SERVICE_PAYMENT_835}${id}`;

    const queryParams = { start, limit, order, orderBy };

    return axios.get(url, { params: queryParams });
  },

  getAppealService(params) {
    return genericAxiosCall(
      `${serverRoutes?.APPEAL_SERVICE}`,
      "get",
      "",
      params
    );
  },
  generateAppealEmail(msg) {
    const params = {
      userMsg: msg,
      chatsession: true,
      userId: returnUserId(),
    };

    return axiosFetch.post(serverRoutes?.GENERATE_APPEAL_EMAIL, params, {
      // cancelToken: cancelToken.token,
      headers: headerVersion2(),
    });
  },
  generateMedicalNotes(body) {
    return genericAxiosCall(
      `${serverRoutes?.GENERATE_MEDICAL_NOTES}`,
      "post",
      body,
      ""
    );

    // return axiosFetch.post(serverRoutes?.GENERATE_APPEAL_REASON, params, {
    //   // cancelToken: cancelToken.token,
    //   headers: headerVersion2(),
    // });
  },
  generateAppealReason(msg, payerName, cms = false) {
    const params = {
      userMsg: msg,
      chatsession: true,
      payerName: payerName,
      cms: cms,
      userId: returnUserId(),
    };
    // return genericAxiosCall(
    //   `${serverRoutes?.GENERATE_APPEAL_REASON}`,
    //   "post",
    //   params,
    //   ""
    // );

    return axiosFetch.post(serverRoutes?.GENERATE_APPEAL_REASON, params, {
      // cancelToken: cancelToken.token,
      headers: headerVersion2(),
    });
  },
  generateAppealEmailClaim(msg) {
    const params = {
      userMsg: msg,
      chatsession: true,
      userId: returnUserId(),
    };

    return axiosFetch.post(serverRoutes?.GENERATE_APPEAL_EMAIL_CLAIM, params, {
      // cancelToken: cancelToken.token,
      headers: headerVersion2(),
    });
  },
  cognitiveSearchGet(params) {
    return genericAxiosCall(
      `${serverRoutes?.COGNITIVE_SEARCH}`,
      "post",
      params,
      ""
    );
  },

  saveGlossary(data) {
    return genericAxiosCall(
      `${serverRoutes?.SAVE_APPEAL_GLOSSARY}`,
      "post",
      data,
      ""
    );
  },
  generateAppealReasonClaim(msg, payerName, cms = false) {
    const params = {
      userMsg: msg,
      chatsession: true,
      payerName: payerName,
      cms: cms,
      userId: returnUserId(),
    };
    // return genericAxiosCall(
    //   `${serverRoutes?.GENERATE_APPEAL_REASON_CLAIM}`,
    //   "post",
    //   params,
    //   ""
    // );
    return axiosFetch.post(serverRoutes?.GENERATE_APPEAL_REASON_CLAIM, params, {
      // cancelToken: cancelToken.token,
      headers: headerVersion2(),
    });
  },
  changeAppealStatus(statusType, claimId, userId) {
    const params = {
      ClaimPaymentId: claimId,

      AppealStatus:
        statusType === "To Do"
          ? "Todo"
          : statusType === "Mark as Complete"
          ? "Mark as Incomplete"
          : statusType,
      TransferBy: userId,
    };
    return genericAxiosCall(
      `${serverRoutes?.CHANGE_APPEAL_STATUS}`,
      "put",
      params,
      ""
    );
  },
  deleteAllAlerts(userId) {
    const params = {
      deletedBy: userId,
    };
    return genericAxiosCall(
      `${serverRoutes?.DELETE_ALL_NOTIFICATIONS}`,
      "delete",
      "",
      params
    );
  },
  deleteIndividualAlert({ userId, id }) {
    const params = {
      deletedBy: userId,
      notificationIds: id,
    };
    return genericAxiosCall(
      `${serverRoutes?.DELETE_INDIVIDUAL_NOTIFICATION}`,
      "delete",
      "",
      params
    );
  },
  getClaimPaymentInformation(id) {
    return axios.get(`/claimpayment/${id}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getClaimPaymentById(id) {
    return axios.get(`${serverRoutes?.GET_PAYMENT_DROPDOWN_835}${id}`, {
      headers: { "X-Subscription": subId },
    });
  },

  getServiceAdjustments(id) {
    return axios.get(`/claimpayment/GetAdjustments/${id}`, {
      headers: { "X-Subscription": subId },
    });
  },

  // this api use for service line adjustments
  getServiceLineAdjustments(id) {
    return axios.get(`${serverRoutes?.DETAIL_SERVICE_ADJUSTMENTS_837}${id}`, {
      headers: { "X-Subscription": subId },
    });
  },

  // this api use for bundle CPTs desc
  getCPTsBundleUseCaseSuggestions(id, cpt) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_DETAIL_CPTS_BUNDLE_SUGGESTIONS}cptCode=${cpt}&claimRequestId=${id}`
    );
  },

  GetServiceAdjustments_PaymentDetail(id) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_DETAIL_SERVICE_ADJUSTMENTS_835}${id}`
    );
  },

  GetClaimAdjustments(id) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_DETAIL_CLAIM_ADJUSTMENTS_835}${id}`
    );
  },

  getMiscellaneousMoreData_ClaimPayment(id) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_DETAIL_CLAIM_MISCELLANEOUS_MORE_DATA_835}${id}`
    );
  },

  getClaimDiagnosisCodes(id) {
    return axios.get(`${serverRoutes?.DETAIL_OTHER_DIAGNOSE_CODE_837}${id}`);
  },

  deleteClaim(id) {
    return axios.delete(`${serverRoutes?.DELETE_PAYMENT_835}${id}`, {
      headers: { "X-Subscription": subId },
    });
  },

  deleteClaimRequest(id) {
    return axios.delete(`${serverRoutes?.DELETE_CLAIM_837}${id}`);
  },

  getDashbaordKPIClaimSum(year) {
    return axios.get(`/Analysis/DashbaordKPIClaimSum?Year=${year}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getDashbaordKPIClaimCount(type) {
    return axios.get(`/Analysis/DashbaordKPIClaimCount?Type=${type}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getDashbaordKPIClaimBilledAndPaid(type) {
    return axios.get(`/Analysis/DashbaordKPIClaimBilledAndPaid?Type=${type}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getDashbaordKPIReimbursement(month, year) {
    return axios.get(
      `/Analysis/DashbaordKPIReimbursement?Month=${month}&Year=${year}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getDashbaordKPIPatientCount(type) {
    return axios.get(`/Analysis/DashbaordKPIPatientCount?Type=${type}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getDashbaordKPIWriteoff(type) {
    return axios.get(`/Analysis/DashbaordKPIWriteoff?Type=${type}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getClaimJCodeAmountDetail(claimId) {
    return axios.get(
      `ServicePayment/ClaimJCodeAmountDetail?ClaimPaymentId=${claimId}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getClaimAdminAmountDetail(claimId) {
    return axios.get(
      `ServicePayment/ClaimAdminAmountDetail?ClaimPaymentId=${claimId}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getClaimAncillaryAmountDetail(claimId) {
    return axios.get(
      `ServicePayment/ClaimAncillaryAmountDetail?ClaimPaymentId=${claimId}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getClaimPreMedAmountDetail(claimId) {
    return axios.get(
      `ServicePayment/ClaimPreMedAmountDetail?ClaimPaymentId=${claimId}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  fileUpload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    return axios.post("http://127.0.0.1:8000/api/uploads", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  },
  getClaimRequest(params) {
    return axios.get(
      `${serverRoutes?.GET_CLAIM_REQUEST_837}${encodeData(params)}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },

  getClaimRequest_testApi(params) {
    return axios.get(
      `claimrequest/GetClaimRequestsRange?${encodeData(params)}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },

  getClaimRequestForFilter_testApi(params) {
    return axios.get(`claimrequest/GetFilteredClaims?${encodeData(params)}`, {
      headers: { "X-Subscription": subId },
    });
  },

  getClaimRequestForFilter_testApi_2nd(params) {
    return axios.get(
      `/claimrequest/GetClaimRequestsFiltered?${encodeData(params)}`
    );
  },

  getClaimRequestsCount(params) {
    return axios.get(
      `/claimrequest/GetClaimRequestsCount?${encodeData(params)}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },

  getClaimService(claimId) {
    return axios.get(
      `${serverRoutes?.DETAIL_OTHER_CLAIM_SERVICE_837}${claimId}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },

  getClaimTypes(claimId) {
    return axios.get(
      `${serverRoutes?.GET_PAYMENT_DETAIL_CLAIM_TYPES_835_UPDATE_API}${claimId}`
    );
  },

  getSingleClaimRequest(claimId) {
    return axios.get(`${serverRoutes?.REQUEST_DETAIL_837}${claimId}`, {
      headers: { "X-Subscription": subId },
    });
  },

  getOriginalClaimId(id) {
    return axios.get(
      `${serverRoutes?.GET_ORIGINAL_CLAIM_ID_FOR_SHOW_BTN_837}${id}`
    );
  },

  getOtherSubscriberInfo(id) {
    return axios.get(`${serverRoutes?.DETAIL_OTHER_SUBSCRIBER_INFO_837}${id}`);
  },

  getOtherSubscriberInfo(id) {
    return axios.get(`${serverRoutes?.DETAIL_OTHER_SUBSCRIBER_INFO_837}${id}`);
  },

  getClaimSummaryMiscellaneousInfo(id) {
    return axios.get(
      `${serverRoutes?.DETAIL_CLAIM_SUMMARY_MISCELLANEOUS_INFO_837}${id}`
    );
  },

  getClaimServiceMiscellaneousInfo(id) {
    return axios.get(
      `${serverRoutes?.DETAIL_CLAIM_SERVICE_MISCELLANEOUS_INFO_837}${id}`
    );
  },

  getSingleClaimRequestSubscriberMoreDetails(claimId) {
    return axios.get(
      `${serverRoutes?.DETAIL_SUBSCRIBER_MORE_INFO_837}${claimId}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getClaimacknowledgment(id) {
    return axios.get(
      `/claimacknowledgment/${id}/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getPatientAcknowledgment(id) {
    return axios.get(`/claimacknowledgment/${id}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getJCodeRejectedDetailByCodeAndPayer(code, payer) {
    return axios.get(
      `CPTCode/GetJCodeRejectedDetailByCodeAndPayer?JCode=${code}&Payer=${payer}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  getPatientClaimTimeLine(id) {
    return axios.get(`${serverRoutes?.GET_PATIENT_TIME_LINE_DATA}${id}`, {
      headers: { "X-Subscription": subId },
    });
  },
  getServiceLineAmountAndSumByDates(startDate, endDate) {
    return axios.get(
      `/denials/ServiceLineAmountAndSumByDates?from=${startDate}&to=${endDate}`,
      {
        headers: { "X-Subscription": subId },
      }
    );
  },
  updateCustomerInfo({
    userEmail,
    firstName,
    lastName,
    organization,
    customerId,
    userTimeZone = null,
  }) {
    // const {}
    const body = {
      CustomerId: customerId,
      FirstName: firstName,
      LastName: lastName,
      Email: userEmail,
      OrgName: organization,
      TimeZone: userTimeZone,
    };
    return axios.put(`/customer/UpdateCustomerBasicInfo`, body, {
      headers: { "X-Subscription": subId },
    });
  },
  updateOrgLogo(logo) {
    const body = {
      OrgLogo: logo,
    };
    return axios.put(`/customer/UpdateCustomerBasicInfo`, body, {
      headers: { "X-Subscription": subId },
    });
  },
  updateSSOConfig({ tennetId, domainId }) {
    return axios.put(
      `${serverRoutes?.SAVE_NEW_TENANT_ID}?azureTennentID=${tennetId}&domainId=${domainId}`,
      {
        ["X-Version"]: "1.0",
        ["X-Domain"]: "8700a71f-b5f5-43a5-be17-492c67008132",
      }
    );
  },
  updateModulePlan(params) {
    return genericAxiosCall(
      `${serverRoutes?.UPDATE_MODULE_PLAN}`,
      "put",
      params,
      ""
    );
  },

  getAllLabels() {
    return axios.get(`https://roversmwdev.azurewebsites.net/tag`, {
      headers: { "X-Subscription": subId },
    });
  },

  getTimeZone() {
    return axios.get(`/common/GetTimeZone`, {
      headers: { "X-Subscription": subId },
    });
  },
  getBuildDetails(token) {
    return axios.get(`/DevOps/GetBuildDetails/43/${token}`);
  },

  // upload files for 837, 835 and 277
  uploadClaim_files(type, body) {
    return axios.post(`/FTPUploader/Upload${type}`, body);
  },
  uploadApiKey(params) {
    const param = { ...params, timeStamp: new Date().getTime() };
    return genericAxiosCall(`${serverRoutes?.SAVE_OPEN_API}`, "get", "", param);
  },
  deleteApiKey(params) {
    const param = { ...params, timeStamp: new Date().getTime() };
    return genericAxiosCall(
      `${serverRoutes?.DELETE_OPEN_API}`,
      "delete",
      "",
      param
    );
  },
  getEligibiltyDoc(fileName, state) {
    const param = {
      fileName,
      index: state,
      timeStamp: new Date().getTime(),
      ...(returnLocationId() && {
        copilotId: returnLocationId(),
      }),
    };
    return genericAxiosCall(
      `${serverRoutes?.GET_ELIGIBILITY_DOC}`,
      "get",
      "",
      param
    );
  },
  getPolicyDoc(fileName, state, index, allMac, stateMac) {
    const param = {
      fileName,
      mac: state,
      index: index,
      allMac: allMac ? allMac : false,
      botName: "policycopilot",
      timeStamp: new Date().getTime(),
      ...(returnLocationId() && {
        copilotId: returnLocationId(),
      }),
      ...(stateMac && {
        stateMac: stateMac,
      }),
    };
    return genericAxiosCall(
      `${serverRoutes?.GET_ELIGIBILITY_DOC}`,
      "get",
      "",
      param
    );
  },
};

export default common;
