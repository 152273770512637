import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  getCurrentClientId,
} from "../../../components/common/Utils/utils";
import { getMultiSelectFilterValueForApi } from "../../../components/common/Utils/utils";

export const CustomProceCodeSlice = createSlice({
  name: "CustomProceCodeSlice",
  initialState: {
    customProceCodeTabularArrList: [],
    customProceCodeLookUpLoader: false,
    customProceCodeLookUpPayers: [],
    customProceCodeLookUpProceCodes: [],
  },
  reducers: {
    setCustomProceCodeTabular_data: (state, action) => {
      let data = action.payload;

      if (data?.length) {
        state.customProceCodeTabularArrList = data;
      } else {
        state.customProceCodeTabularArrList = [];
      }
    },

    setCustomProceCodeLookUPLoader: (state, action) => {
      let data = action.payload;
      state.customProceCodeLookUpLoader = data;
    },

    setCustomProceCodeLookUP_Data: (state, action) => {
      let data = action.payload;

      if (data?.CPTCodes?.length) {
        let list = data?.CPTCodes?.map((item) => {
          return {
            value: item?.code,
            label: `${item?.code}-${item?.description}`,
          };
        });

        state.customProceCodeLookUpProceCodes = list;
      } else {
        state.customProceCodeLookUpProceCodes = [];
      }
      if (data?.Payer?.length) {
        let list = data?.Payer?.map((item) => {
          return {
            value: item?.payerId,
            label: `${item?.payerName}`,
          };
        });

        state.customProceCodeLookUpPayers = list;
      } else {
        state.customProceCodeLookUpPayers = [];
      }
    },
  },
});

export const {
  setCustomProceCodeTabular_data,
  setCustomProceCodeLookUPLoader,
  setCustomProceCodeLookUP_Data,
} = CustomProceCodeSlice.actions;

// get activity logs list
export const gettingCustsomProceCodeList_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "customProceCodeFilter",
            ignore: ["claimStatus,payers,cptCodes,CARC"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "customProceCodeFilter",
            ignore: [
              "BillingProviderIds",
              "frequencyType",
              "frequencyType",
              "search",
            ],
          })
        );
      }
    }
    let sliceState = getState().npReducers.filters.customProceCodeFilter;

    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    //
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.customProceCodeFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId?.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),

        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId?.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setCustomProceCodeTabular_data,
        "customProceCodeFilter",
        "GET_CUSTOM_PROCE_CODE_ALL_LIST",
        false,
        "",
        ""
      );

      ///// API CALL //////
    } catch (e) {
      console.log("e--->>", e);
    }
  };

// this API for Payers and ProcedureCodes lookup data while creating or updating Custom Procedure Code Rule
export const gettingCustomProceCodeConfigLookUp_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.customProceCodeFilter;
    dispatch(setCustomProceCodeLookUPLoader(true));

    try {
      const res = await genericAxiosCall(
        serverRoutes?.GET_CUSTOM_PROCE_CODE_LOOK_UP_DATA,
        "get",
        "",
        ""
      );
      if (res?.data?.success == true) {
        let data = res?.data?.data;
        dispatch(setCustomProceCodeLookUP_Data(data));
        // dispatch(setSelectedClientIds(data?.[0]?.value));
      }

      dispatch(setCustomProceCodeLookUPLoader(false));
    } catch (e) {
      console.log("e--->>", e);
      dispatch(setCustomProceCodeLookUPLoader(false));
    }
  };

// add and update slicer

export const addUpdateCustomProceCode_Call =
  (obj, method, setSubmitLoading, setInputs, newhandleClose) =>
  async (dispatch, getState) => {
    try {
      const route =
        method == "put"
          ? serverRoutes.GET_CUSTOM_PROCE_CODE_UPDATE
          : serverRoutes.GET_CUSTOM_PROCE_CODE_SAVE;

      const res = await genericAxiosCall(route, method, obj, "");

      if (res?.data?.success) {
        const { message } = res?.data;
        toast.success(message, { position: toast.POSITION.TOP_CENTER });

        setInputs({
          ProcedureRuleName: "",
          cptCode: [],
          payers: [],
          suggestedCpt: [],
        });
        newhandleClose();
        dispatch(
          setListingState({
            listing: "customProceCodeFilter",
            value: { fetchLatest: true },
          })
        );

        dispatch(gettingCustsomProceCodeList_Call());
      } else if (res?.data?.success === false) {
        const { message } = res?.data;
        toast.error(message, { position: toast.POSITION.TOP_CENTER });
      }
    } catch (error) {
      // console.log(" --->>", error);
    } finally {
      setSubmitLoading(false);
    }
  };

// delete single client
export const deleteSingleCustomProceCode_Call =
  (id, setLoadingDeleteClass, setOpenDeleteModal, setUpdateObj, list) =>
  async (dispatch, getState) => {
    try {
      const res = await genericAxiosCall(
        `${serverRoutes.GET_CUSTOM_PROCE_CODE_DELETE}${id}`,
        "delete",
        "",
        ""
      );
      if (res?.data?.success === true) {
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });

        setOpenDeleteModal(false);
        dispatch(
          setListingState({
            listing: "customProceCodeFilter",
            value: {
              fetchLatest: true,
            },
          })
        );
        setUpdateObj({});
        if (list.length === 1) {
          console.log();
          dispatch(
            setListingState({
              listing: "customProceCodeFilter",
              value: {
                pageNumber: 1,
              },
            })
          );
        }
        dispatch(gettingCustsomProceCodeList_Call());

        return res;
      }
    } catch (error) {
      // console.log("e0--->>", error);
    } finally {
      setLoadingDeleteClass("");
    }
  };

export default CustomProceCodeSlice.reducer;
