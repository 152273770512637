import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  IconButton,
  TextField,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { MentionsInput, Mention } from "react-mentions";
import BootstrapTooltip from "../../components/common/TooltipUI/customToolTip";

import withMainLayout from "../../HOC/withMainLayout";

import DashboardTopNav from "../../components/DashboardTopNav/DashboardTopNav";
import ChatIconBot from "../AskZoe/ChatIconBot";

import defaultStyle from "../AskZoe/defaultStyle";

import ChatbotLoading from "../../assets/images/ChatbotLoading.gif";

import UserIcon from "../../assets/ZoeImages/user.svg";
import BotIcon from "../../assets/ZoeImages/DRCopilot.svg";

import CommentSendIcon from "../../assets/svgs/CommentSendIcon";
import PropTypes from "prop-types";

import DRLogo from "../../assets/images/DRLogo.png";

import genericAxiosCall, {
  checkTokenExpiration,
} from "../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../constants/serverRoutes";
import {
  getAmountInKAndM,
  getUserId,
  getUserName,
  headerVersion,
  returnUserId,
} from "../../components/common/Utils/utils";

import axios from "axios";
import { useSelector } from "react-redux";
import { Button, Popover } from "antd";
import ExplainIcon from "../../components/ChatBotNew/ExplainIcon";
import RepairIcon from "../../components/ChatBotNew/RepairIcon";
import { useNavigate } from "react-router-dom";
import browserRoute from "../../constants/browserRoutes";
import { Image, ConfigProvider } from "antd";
import {
  convertLunaEncryptedFile,
  LidaInstructions,
  LidaPlottingInstructions,
  LidaPlottingInstructionsDataFrame,
  SAM_BOT_NAME,
} from "../../components/common/Utils/copilot";
import CommentSendGreenZoe from "../../assets/svgs/CommentSendGreenZoe";
import DRGroupQuestions from "./DRGroupQuestions";
import { lunaSampleGroupQuestions } from "../../components/common/Utils/copilot";
import MenuNewChatBot from "./MenuNewChatBot";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const ChatBotResultNew = () => {
  const chatbotEnum = {
    bot: "bot",
    user: "user",
    policy: "policy",
    claim: "claim",
  };
  const typeAnsEnum = {
    Plot: "plot",
    DataFrame: "dataframe",
  };
  const data = [
    // {
    //   msg: "Welcome to the DataRovers Insights, How may i help you?",
    //   role: chatbotEnum?.bot,
    //   citation: "",
    // },
  ];
  const errorsList = ["An error occurred while processing the request"];
  const errorHandlerMsg =
    "Hi! I'm a visualization chatbot here to help with your queries and visualize them. If your question isn't about visualization, could you rephrase it?";
  const sampleQuestions = [
    {
      question: "Which CPT codes have the highest denial rates this quarter?",
      icon: (
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_828_55)">
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#131722"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M5 12V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V12"
              stroke="#131722"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M8 17.5C8 17.1022 7.84196 16.7206 7.56066 16.4393C7.27936 16.158 6.89782 16 6.5 16C6.10218 16 5.72064 16.158 5.43934 16.4393C5.15804 16.7206 5 17.1022 5 17.5V20.5C5 20.8978 5.15804 21.2794 5.43934 21.5607C5.72064 21.842 6.10218 22 6.5 22C6.89782 22 7.27936 21.842 7.56066 21.5607C7.84196 21.2794 8 20.8978 8 20.5"
              stroke="#131722"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M11 19H12.5C12.8978 19 13.2794 18.842 13.5607 18.5607C13.842 18.2794 14 17.8978 14 17.5C14 17.1022 13.842 16.7206 13.5607 16.4393C13.2794 16.158 12.8978 16 12.5 16H11V22"
              stroke="#131722"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M16 16H19"
              stroke="#131722"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M17.5 16V22"
              stroke="#131722"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
      ),
    },
    {
      question:
        "Show me a bar chart of the most common reasons for claim denials.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          width="32"
          height="32"
          stroke-width="1"
        >
          <path d="M3 13a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
          <path d="M15 9a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
          <path d="M9 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
          <path d="M4 20h14"></path>
        </svg>
      ),
    },
    {
      question: "Show me a bar chart comparing denial rates across payers.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#131722"
          stroke-linecap="round"
          stroke-linejoin="round"
          width="32"
          height="32"
          stroke-width="1"
        >
          <path d="M3 3v18h18"></path>
          <path d="M20 18v3"></path>
          <path d="M16 16v5"></path>
          <path d="M12 13v8"></path>
          <path d="M8 16v5"></path>
          <path d="M3 11c6 0 5 -5 9 -5s3 5 9 5"></path>
        </svg>
      ),
    },
  ];
  const msgPointList = [
    "date",
    "payer",
    "cpt",
    "reason",
    "money",
    "code",
    "charge",
    "denial",
    "denied",
    "denies",
    "facility",
    "group",
    "amount",
    "revenue",
  ];
  const msgPointListTabular = ["tabular", "table", "tables"];

  const messagesDivRef = useRef(null);

  const [input, setInput] = useState("");
  const [mainOutput, setMainOutput] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [promptOpen, setPromptOpen] = useState("");
  const [repairMsg, setRepairMsg] = useState("");
  const [openCollaps, setOpenCollaps] = useState(true);

  const handleButtonClick = () => {
    handleSubmit(); // Second action
  };

  const scrollToBottom = () => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop =
        messagesDivRef.current.scrollHeight + 20;
    }
  };

  const closePrompt = (index) => {
    setPromptOpen("");
  };
  const handleLoading = (val, output) => {
    setLoading(val);
    if (val) {
      output.push({
        msg: "loading",
        role: "loading",
        citation: "",
      });
    } else {
      output.pop();
    }
    setMainOutput(output);
  };
  const saveMessageToLocalHost = (obj) => {
    let savedMessages = localStorage.getItem("saved_Messages_Dashboard");
    setTimeout(scrollToBottom, 0);
    if (savedMessages) {
      let arr = [];
      savedMessages = JSON.parse(savedMessages);

      arr.push(...savedMessages);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Dashboard", JSON.stringify(arr));
    } else {
      let arr = [];
      //  savedMessages = JSON.parse(savedMessages);
      //  arr.push([...savedMessages]);
      // arr.push(tempMessage);
      arr.push(obj);
      localStorage.setItem("saved_Messages_Dashboard", JSON.stringify(arr));
    }
  };

  const loadSavedMessages = () => {
    const messages = JSON.parse(
      localStorage.getItem("saved_Messages_Dashboard")
    );

    let outputRes;
    if (messages?.length) {
      messages.unshift(...data);
      setMainOutput(messages);
      outputRes = messages;
      setTimeout(scrollToBottom, 0);
    } else {
      setMainOutput(data);
      outputRes = data;
    }
    return outputRes;
  };

  const deleteSavedMessages = () => {
    localStorage.removeItem("saved_Messages_Dashboard");
    setMainOutput(data);
  };
  const TabularData = ({ data }) => {
    // Get keys as headers
    const headers = Object.keys(data);

    // Get the number of rows by checking the length of the first key's array
    const rowCount = data[headers[0]].length;

    return (
      <TableContainer
        component={Paper}
        style={{ maxHeight: "400px" }}
        className="DR-new-SP-table-compack bg-white icn-btn-tb tb-scroll"
      >
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headers?.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(rowCount)].map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <TableCell key={colIndex}>
                    {" "}
                    {typeof data[header][rowIndex] === "number"
                      ? getAmountInKAndM(data[header][rowIndex])
                      : data[header][rowIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleSyntaxText = (msg, item) => {
    if (item?.image) {
      // const imageUrl = `data:image/png;base64,${responseBase64}`;

      // return <img src={msg} />;

      return (
        <div className="DR-Custom-Chat-msg-bot" style={{ margin: "0" }}>
          <div
            className="flex items-center"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "5px",
            }}
          >
            <img
              style={{ width: "28px", marginRight: "5px", boxShadow: "none" }}
              src={BotIcon}
            />
            <span>{SAM_BOT_NAME}</span>
          </div>
          <div
            className="DR-Custom-Chat-msg-bot-inner flex items-center"
            style={{
              background: "none",
              maxWidth: "95%",
              width: "100%",
              padding: "0",
              boxShadow: "none",
            }}
          >
            {/* <img style={{ width: "80%", backgroundColor: "#fff" }} src={msg} />
             */}
            <div className="DR-bot-image-preview">
              <ConfigProvider
                theme={{
                  components: {
                    Image: {},
                    zIndexPopup: 1980,
                  },
                  token: {
                    zIndexPopupBase: 1900,
                  },
                }}
              >
                <Image
                  style={{ width: "80%", backgroundColor: "#fff" }}
                  src={msg}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      );
    } else if (item?.table) {
      return <TabularData data={msg} />;
    } else if (item?.html) return <RenderHTMLResponse data={msg} item={item} />;
    else {
      return (
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {" "}
          <div className="DR-Custom-Chat-msg-bot" style={{ margin: "0" }}>
            <div
              className="flex items-center"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              <img
                style={{
                  width: "28px",
                  marginRight: "5px",
                  boxShadow: "none",
                }}
                src={BotIcon}
              />
              <span>{SAM_BOT_NAME}</span>
            </div>
            <div
              className="DR-Custom-Chat-msg-bot-inner"
              style={{
                background: "none",
                maxWidth: "95%",
                width: "100%",
                padding: "0",
                boxShadow: "none",
              }}
            >
              {msg}
            </div>
          </div>
        </pre>
      );
    }
  };
  const checkIsTableQuestion = (msg) => {
    const containsElement = msgPointListTabular.some((element) =>
      msg?.toLowerCase().includes(element)
    );
    return containsElement;
  };
  const fetchData = async (msg, outputTemp) => {
    try {
      const params = {
        prompt: msg?.toLowerCase(),
        instructions: LidaInstructions,
        plotting_instructions: LidaPlottingInstructions,
        typeans: typeAnsEnum?.Plot,
        user_id: getUserId(),
      };
      handleLoading(true, outputTemp);
      setTimeout(scrollToBottom, 0);
      const containsElement = msgPointList.some((element) =>
        msg?.toLowerCase().includes(element)
      );
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${applicationToken}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (containsElement) {
        axios
          .post(serverRoutes?.SEND_ASK_CHATBOT, params, {
            // responseType: "arraybuffer",
            headers: headers,
          })
          .then((response) => {
            if (
              errorsList?.includes(response?.data?.response) ||
              !response?.data?.success
            ) {
              handleLoading(false, outputTemp);

              let output = [...outputTemp];
              const botMsg = {
                msg: errorHandlerMsg,
                role: chatbotEnum?.bot,
                image: false,
                userMsg: msg,
                // citation: citation,
                // type: chatbotEnum?.policy,
              };
              output.push(botMsg);

              saveMessageToLocalHost(botMsg);

              setMainOutput(output);
            } else {
              const data = JSON.parse(response?.data?.response);
              const raster = data?.ImageData;
              const code = data?.Message;
              // const imageUrl = `data:image/png;base64,${raster}`;
              const imageUrl = raster;

              // const imageUrl = `data:${
              //   response.headers["content-type"]
              // };base64,${btoa(
              //   String.fromCharCode(...new Uint8Array(response.data))
              // )}`;

              handleLoading(false, outputTemp);

              let output = [...outputTemp];
              const botMsg = {
                msg: imageUrl,
                role: chatbotEnum?.bot,
                // image: true,
                html: true,
                userMsg: msg,
                code: code,

                // citation: citation,
                // type: chatbotEnum?.policy,
              };
              output.push(botMsg);

              saveMessageToLocalHost(botMsg);

              setMainOutput(output);
            }
          })
          .catch((err) => {
            handleLoading(false, outputTemp);
            let output = [...outputTemp];

            const botMsg = {
              msg:
                err?.response?.status === 503
                  ? err?.response?.data?.message
                  : errorHandlerMsg,
              role: chatbotEnum?.bot,
              citation: "",
              userMsg: msg,
            };
            output.push(botMsg);
            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          });
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: errorHandlerMsg,
          role: chatbotEnum?.bot,
          image: false,
          // hitUrl: true,
          userMsg: msg,

          // citation: citation,
          // type: chatbotEnum?.policy,
        };
        output.push(botMsg);

        saveMessageToLocalHost(botMsg);
        setInput("");

        setMainOutput(output);
      }

      // handleSyntaxText(resMsg, citation);
    } catch (error) {
      // return { resMsg: "Error occurred while fetching data.", citation: "" };
    }
  };
  const fetchDataWithSample = async (msg) => {
    setTimeout(scrollToBottom, 0);
    let output = [...mainOutput];
    setLoading(true);
    let userMsg = {
      msg: msg,
      role: chatbotEnum?.user,
      citation: "",
      time: new Date().getTime(),
    };
    output.push(userMsg);
    setMainOutput(output);
    saveMessageToLocalHost(userMsg);
    setInput("");
    fetchData(msg, output);
  };
  const fetchDataTabular = async (msg, outputTemp) => {
    try {
      const params = {
        prompt: msg?.toLowerCase(),
        instructions: LidaInstructions,
        plotting_instructions: LidaPlottingInstructionsDataFrame,
        typeans: typeAnsEnum?.DataFrame,
        user_id: getUserId(),
      };
      handleLoading(true, outputTemp);
      setTimeout(scrollToBottom, 0);
      const containsElement = msgPointList.some((element) =>
        msg?.toLowerCase().includes(element)
      );
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${applicationToken}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (containsElement) {
        axios
          .post(serverRoutes?.SEND_ASK_CHATBOT, params, {
            // responseType: "arraybuffer",
            headers: headers,
          })
          .then((response) => {
            if (
              errorsList?.includes(response?.data?.response) ||
              !response?.data?.success
            ) {
              handleLoading(false, outputTemp);

              let output = [...outputTemp];
              const botMsg = {
                msg: errorHandlerMsg,
                role: chatbotEnum?.bot,
                image: false,
                userMsg: msg,
                // citation: citation,
                // type: chatbotEnum?.policy,
              };
              output.push(botMsg);

              saveMessageToLocalHost(botMsg);

              setMainOutput(output);
            } else {
              let data = JSON.parse(response?.data?.response);
              if (data?.index) {
                delete data.index;
              }

              handleLoading(false, outputTemp);

              let output = [...outputTemp];
              const botMsg = {
                msg: data,
                role: chatbotEnum?.bot,
                table: true,
                userMsg: msg,
              };

              output.push(botMsg);

              saveMessageToLocalHost(botMsg);

              setMainOutput(output);
            }
          })
          .catch((err) => {
            handleLoading(false, outputTemp);
            let output = [...outputTemp];

            const botMsg = {
              msg:
                err?.response?.status === 503
                  ? err?.response?.data?.message
                  : errorHandlerMsg,
              role: chatbotEnum?.bot,
              citation: "",
              userMsg: msg,
            };
            output.push(botMsg);
            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          });
      } else {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];
        const botMsg = {
          msg: errorHandlerMsg,
          role: chatbotEnum?.bot,
          image: false,
          // hitUrl: true,
          userMsg: msg,

          // citation: citation,
          // type: chatbotEnum?.policy,
        };
        output.push(botMsg);

        saveMessageToLocalHost(botMsg);
        setInput("");

        setMainOutput(output);
      }

      // handleSyntaxText(resMsg, citation);
    } catch (error) {
      // return { resMsg: "Error occurred while fetching data.", citation: "" };
    }
  };

  const { applicationToken, domainID, subscriptionID } = useSelector(
    (state) => state.pReducers.user
  );
  const fetchData2 = async (msg, outputTemp) => {
    try {
      // const params = {
      //   Messages: getMessages(msg),
      //   chatSession: true,
      // };

      const params = {
        UserMsg: msg,
        chatSession: true,
        userId: returnUserId(),
        // index: index,
        // mac: macAddress,
      };
      handleLoading(true, outputTemp);
      // const response = await genericAxiosCall(
      //   serverRoutes?.SEND_ASK_ROVERS,
      //   "post",
      //   params,
      //   ""
      // );
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${applicationToken}`,
        ["X-Version"]: "1.0",
        [!domainID ? "verification-api" : "X-Domain"]: domainID,
        ["X-Subscription"]: subscriptionID,
        ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      await checkTokenExpiration();
      const response = await axios.post(
        serverRoutes?.SEND_ASK_COPILOT,
        params,
        {
          //  cancelToken: cancelToken.token,
          headers: headers,
        }
      );
      handleLoading(false, outputTemp);
      if (
        response?.data?.success &&
        response?.data?.data?.choices?.length &&
        response?.data?.data?.choices[0]?.message &&
        response?.data?.data?.choices[0]?.message?.content
      ) {
        const responseMsg = response?.data?.data?.choices[0]?.message?.content;

        let output = [...outputTemp];

        const botMsg = {
          msg: responseMsg,
          role: chatbotEnum?.bot,
          image: false,
          // citation: citation,
          // type: chatbotEnum?.policy,
        };
        output.push(botMsg);

        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      } else {
        let output = [...outputTemp];
        const botMsg = {
          msg: errorHandlerMsg,
          role: chatbotEnum?.bot,
          image: false,
          // citation: citation,
          // type: chatbotEnum?.policy,
        };
        output.push(botMsg);

        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      }
    } catch (error) {
      handleLoading(false, outputTemp);

      let output = [...outputTemp];
      const botMsg = {
        msg: errorHandlerMsg,
        role: chatbotEnum?.bot,
        image: false,
        // citation: citation,
        // type: chatbotEnum?.policy,
      };
      output.push(botMsg);

      saveMessageToLocalHost(botMsg);

      setMainOutput(output);
    }
  };
  const fetchDataWithSample2 = async (msg) => {
    setTimeout(scrollToBottom, 0);
    let output = [...mainOutput];
    setLoading(true);
    let userMsg = {
      msg: msg,
      role: chatbotEnum?.user,
      citation: "",
      time: new Date().getTime(),
    };
    output.push(userMsg);
    setMainOutput(output);
    saveMessageToLocalHost(userMsg);
    setInput("");
    fetchData2(msg, output);
  };

  const notFoundLink = (item) => {
    fetchDataWithSample2(item?.userMsg);
  };
  const drSampleQuestionClick = (item) => {
    if (!loading) {
      // fetchDataWithSample(item);
      setInput(item);
    }
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      handleSubmit();
    }
  };

  const handleSubmit = async (event) => {
    if (input?.trim()?.length && !loading) {
      setTimeout(scrollToBottom, 0);
      let output = [...mainOutput];
      setLoading(true);
      let userMsg = {
        msg: input,
        role: chatbotEnum?.user,
        citation: "",
      };
      output.push(userMsg);

      setMainOutput(output);

      saveMessageToLocalHost(userMsg);

      setInput("");
      checkIsTableQuestion(input)
        ? fetchDataTabular(input, output)
        : fetchData(input, output);
    }
  };

  const handleExplain = (item) => {
    let outputTemp = [...mainOutput];
    handleLoading(true, outputTemp);

    const params = {
      prompt: item?.code,
      instructions: LidaInstructions,
      plotting_instructions: LidaPlottingInstructions,
    };
    axios
      .post(serverRoutes?.SEND_ASK_CHATBOT_EXPLAIN, params, {
        // responseType: "arraybuffer",
        headers: headerVersion(),
      })
      .then((response) => {
        if (response?.data?.success && response?.data?.response) {
          const data = JSON.parse(response?.data?.response)?.[0]?.[0];

          // const imageUrl = `data:${
          //   response.headers["content-type"]
          // };base64,${btoa(
          //   String.fromCharCode(...new Uint8Array(response.data))
          // )}`;

          handleLoading(false, outputTemp);

          let output = [...outputTemp];
          const botMsg = {
            msg: data?.explanation,
            role: chatbotEnum?.bot,
            image: false,
            // userMsg: msg,
            // code: code,

            // citation: citation,
            // type: chatbotEnum?.policy,
          };
          output.push(botMsg);
          saveMessageToLocalHost(botMsg);
          setMainOutput(output);
        } else {
          let output = [...outputTemp];
          const botMsg = {
            msg: errorHandlerMsg,
            role: chatbotEnum?.bot,
            image: false,
            // userMsg: msg,
            // citation: citation,
            // type: chatbotEnum?.policy,
          };
          output.push(botMsg);

          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        }
      })
      .catch((err) => {
        handleLoading(false, outputTemp);
        let output = [...outputTemp];

        const botMsg = {
          msg:
            err?.response?.status === 503
              ? err?.response?.data?.message
              : errorHandlerMsg,
          role: chatbotEnum?.bot,
          citation: "",
          userMsg: item?.userMsg,
        };
        output.push(botMsg);
        saveMessageToLocalHost(botMsg);

        setMainOutput(output);
      });
  };
  const handleTabularVersion = (item) => {
    let outputTemp = [...mainOutput];
    handleLoading(true, outputTemp);
    setTimeout(scrollToBottom, 0);

    const params = {
      prompt: item?.userMsg?.toLowerCase(),
      instructions: LidaInstructions,
      plotting_instructions: LidaPlottingInstructionsDataFrame,
      typeans: typeAnsEnum?.DataFrame,
      user_id: getUserId(),
    };
    const containsElement = msgPointList.some((element) =>
      item?.userMsg?.toLowerCase().includes(element)
    );
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${applicationToken}`,
      ["X-Version"]: "1.0",
      [!domainID ? "verification-api" : "X-Domain"]: domainID,
      ["X-Subscription"]: subscriptionID,
      ["TimeZoneHeader"]: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (containsElement) {
      axios
        .post(serverRoutes?.SEND_ASK_CHATBOT, params, {
          // responseType: "arraybuffer",
          headers: headers,
        })
        .then((response) => {
          if (
            errorsList?.includes(response?.data?.response) ||
            !response?.data?.success
          ) {
            handleLoading(false, outputTemp);

            let output = [...outputTemp];
            const botMsg = {
              msg: errorHandlerMsg,
              role: chatbotEnum?.bot,
              image: false,
              userMsg: item?.userMsg,
              // citation: citation,
              // type: chatbotEnum?.policy,
            };
            output.push(botMsg);

            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          } else {
            let data = JSON.parse(response?.data?.response);
            if (data?.index) {
              delete data.index;
            }

            handleLoading(false, outputTemp);

            let output = [...outputTemp];
            const botMsg = {
              msg: data,
              role: chatbotEnum?.bot,
              table: true,
              userMsg: item?.userMsg,
            };
            output.push(botMsg);

            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          }
        })
        .catch((err) => {
          handleLoading(false, outputTemp);
          let output = [...outputTemp];

          const botMsg = {
            msg:
              err?.response?.status === 503
                ? err?.response?.data?.message
                : errorHandlerMsg,
            role: chatbotEnum?.bot,
            citation: "",
            userMsg: item?.userMsg,
          };
          output.push(botMsg);
          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        });
    } else {
      handleLoading(false, outputTemp);
      let output = [...outputTemp];
      const botMsg = {
        msg: errorHandlerMsg,
        role: chatbotEnum?.bot,
        image: false,
        // hitUrl: true,
        userMsg: item?.userMsg,

        // citation: citation,
        // type: chatbotEnum?.policy,
      };
      output.push(botMsg);

      saveMessageToLocalHost(botMsg);
      setInput("");

      setMainOutput(output);
    }
  };
  const handleRepair = (item) => {
    if (repairMsg?.trim()) {
      let outputTemp = [...mainOutput];
      handleLoading(true, outputTemp);
      closePrompt();
      setTimeout(scrollToBottom, 0);

      const params = {
        prompt: item?.code,
        instructions: [repairMsg],
      };
      axios
        .post(serverRoutes?.SEND_ASK_CHATBOT_REPAIR, params, {
          // responseType: "arraybuffer",
          headers: headerVersion(),
        })
        .then((response) => {
          if (
            errorsList?.includes(response?.data?.response) ||
            !response?.data?.success
          ) {
            handleLoading(false, outputTemp);

            let output = [...outputTemp];
            const botMsg = {
              msg: errorHandlerMsg,
              role: chatbotEnum?.bot,
              image: false,
              // userMsg: msg,
              // citation: citation,
              // type: chatbotEnum?.policy,
            };
            output.push(botMsg);

            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          } else {
            const data = JSON.parse(response?.data?.response);
            const raster = data?.raster;
            const code = data?.code;
            const imageUrl = `data:image/png;base64,${raster}`;
            // const imageUrl = `data:${
            //   response.headers["content-type"]
            // };base64,${btoa(
            //   String.fromCharCode(...new Uint8Array(response.data))
            // )}`;

            handleLoading(false, outputTemp);

            let output = [...outputTemp];
            const botMsg = {
              msg: imageUrl,
              role: chatbotEnum?.bot,
              image: true,
              userMsg: item?.userMsg,
              code: code,

              // citation: citation,
              // type: chatbotEnum?.policy,
            };
            output.push(botMsg);

            saveMessageToLocalHost(botMsg);

            setMainOutput(output);
          }
        })
        .catch((err) => {
          handleLoading(false, outputTemp);
          let output = [...outputTemp];

          const botMsg = {
            msg:
              err?.response?.status === 503
                ? err?.response?.data?.message
                : errorHandlerMsg,
            role: chatbotEnum?.bot,
            citation: "",
            // userMsg: msg,
          };
          output.push(botMsg);
          saveMessageToLocalHost(botMsg);

          setMainOutput(output);
        });
    }
  };

  // For tabs
  const initialRender = async () => {
    const param = new URLSearchParams(window.location.search);
    let searchValue = param.get("search");
    const outputRes = loadSavedMessages();

    if (searchValue) {
      setTimeout(scrollToBottom, 0);

      let output = [...outputRes];

      setLoading(true);
      let userMsg = {
        msg: searchValue,
        role: chatbotEnum?.user,
        citation: "",
      };
      output.push(userMsg);

      setMainOutput(output);

      saveMessageToLocalHost(userMsg);

      setInput("");

      navigate(`${browserRoute?.CHAT_BOT_RESULT}`, { replace: true });
      setTimeout(scrollToBottom, 0);

      checkIsTableQuestion(searchValue)
        ? fetchDataTabular(searchValue, output)
        : fetchData(searchValue, output);
    }
  };
  useEffect(() => {
    initialRender();
  }, []);
  useEffect(() => {
    if (!promptOpen) {
      setRepairMsg("");
    }
  }, [promptOpen]);

  const RenderHTMLResponse = ({ data, item }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
      if (data && iframeRef.current) {
        const newData = convertLunaEncryptedFile(data);
        const iframeDoc =
          iframeRef.current.contentDocument ||
          iframeRef.current.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(newData);
        iframeDoc.close();
      }
    }, [data]);
    return (
      <div className="DR-Custom-Chat-msg-bot" style={{ margin: "0" }}>
        <div
          className="flex items-center"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "5px",
          }}
        >
          <img
            style={{ width: "28px", marginRight: "5px", boxShadow: "none" }}
            src={BotIcon}
          />
          <span>{SAM_BOT_NAME}</span>
        </div>
        <div
          className="DR-Custom-Chat-msg-bot-inner flex items-center"
          style={{
            background: "none",
            maxWidth: "95%",
            width: "100%",
            padding: "0",
            boxShadow: "none",
          }}
        >
          <iframe
            ref={iframeRef}
            title="Dynamic Content"
            style={{
              width: "100%",
              height: "600px",
              border: "none",
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      <DashboardTopNav
        title="Insights"
        datePicker={true}
        topSearchHide={true}
        topSearchBot={true}
      />
      <MenuNewChatBot />

      <div
        className="dashboard-main only-bot-resul-left-pad w-full"
        style={{
          backgroundColor: "#fff",
          height: "calc(100vh - 40px)",
          overflowY: "auto",
          paddingBottom: "150px",
          marginTop: "40px",
          position: "relative",
        }}
        ref={messagesDivRef}
      >
        {/* <div style={{ position: "absolute", top: "-12%", left: "-6%" }}>
          <svg
            width="500"
            height="500"
            viewBox="0 0 400 400"
            fill="none"
            stroke="rgba(7, 167, 132, 0.06)"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-width="10"
              d="M206.81 27.3114L247.598 154.774L248.359 157.154L250.72 157.973L371.757 200L250.72 242.027L248.359 242.846L247.598 245.226L206.811 372.689L152.239 244.788L151.37 242.752L149.28 242.027L28.2434 200L149.28 157.973L151.37 157.248L152.239 155.212L206.81 27.3114Z"
            />
            <path
              stroke-width="10"
              d="M201.57 158.311L210.918 187.524L211.679 189.904L214.04 190.723L240.757 200L214.04 209.277L211.679 210.096L210.918 212.476L201.57 241.689L188.919 212.038L188.05 210.002L185.96 209.277L159.243 200L185.96 190.723L188.05 189.998L188.919 187.962L201.57 158.311Z"
            />
          </svg>
        </div> */}

        <div
          className="DR-Ava-main"
          // style={{ maxWidth: "calc(100% - 365px)" }}
          style={{
            maxWidth: openCollaps === true ? "100%" : "calc(100% - 365px)",
          }}
        >
          <div className="DR-fix-wid-inner">
            <div
              className="DR-Custom-Chat-msg-main mt-4"
              style={{ overflow: "hidden", paddingLeft: "45px" }}
            >
              <div className="DR-Ava-body-content-main">
                <Grid container direction="row" alignItems="center">
                  <Grid item sm={12}>
                    {/* <img
                      src={DRLogo}
                      style={{
                        width: "50px",
                        display: "block",
                      }}
                    /> */}
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 400 400"
                      fill="none"
                      stroke="#07A784"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-width="12"
                        d="M206.81 27.3114L247.598 154.774L248.359 157.154L250.72 157.973L371.757 200L250.72 242.027L248.359 242.846L247.598 245.226L206.811 372.689L152.239 244.788L151.37 242.752L149.28 242.027L28.2434 200L149.28 157.973L151.37 157.248L152.239 155.212L206.81 27.3114Z"
                      />
                      <path
                        stroke-width="12"
                        d="M201.57 158.311L210.918 187.524L211.679 189.904L214.04 190.723L240.757 200L214.04 209.277L211.679 210.096L210.918 212.476L201.57 241.689L188.919 212.038L188.05 210.002L185.96 209.277L159.243 200L185.96 190.723L188.05 189.998L188.919 187.962L201.57 158.311Z"
                      />
                    </svg>
                  </Grid>
                  <Grid item sm={12}>
                    <p
                      style={{
                        fontSize: "36px",
                        lineHeight: "44px",
                        fontWeight: "600",
                        marginTop: "20px",
                      }}
                      className="DR-text-gre-cl"
                    >
                      <span style={{ textTransform: "uppercase" }}>
                        {SAM_BOT_NAME}
                      </span>{" "}
                      is your AI-Agent
                    </p>
                    <p
                      style={{
                        fontSize: "36px",
                        lineHeight: "44px",
                        fontWeight: "600",
                        marginTop: "7px",
                        // color: "#a7a7a7",
                      }}
                      className="DR-text-gre-cl"
                    >
                      for creating data visualizations
                    </p>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <p
                      className="flex items-center mb-2"
                      style={{
                        color: "#a7a7a7",
                        fontSize: "14px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#a7a7a7"
                        width="20"
                        height="20"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
                        ></path>
                      </svg>{" "}
                      Suggested
                    </p>
                    <Grid
                      container
                      direction="row"
                      alignItems="stretch"
                      spacing={3}
                    >
                      {sampleQuestions?.map((item, index) => {
                        return (
                          <Grid item sm={4} key={index}>
                            <button
                              className="DR-Bot-choos-btn"
                              style={{ height: "135px" }}
                              onClick={() => {
                                if (!loading) {
                                  fetchDataWithSample(item?.question);
                                }
                              }}
                            >
                              <div
                                className="flex items-center"
                                style={{
                                  marginBottom: "5px",
                                  lineHeight: "18px",
                                }}
                              ></div>
                              <p>{item.question}</p>
                              <div className="flex">{item.icon}</div>
                            </button>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {mainOutput?.length ? (
                    <div
                      // className="DR-Custom-Chat-input-outer"
                      className={
                        openCollaps === true
                          ? "DR-Custom-Chat-input-outer d-full"
                          : "DR-Custom-Chat-input-outer d-half"
                      }
                      style={{
                        zIndex: "5",
                        backgroundColor: "#fff",
                        // maxWidth: "calc(100% - 650px)",
                      }}
                    >
                      <IconButton
                        className="DR-chat-clear-icon"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#07a784",
                        }}
                        disabled={loading}
                        onClick={deleteSavedMessages}
                      >
                        <ChatIconBot />{" "}
                        <span className="DR-chat-clear-icon-text">
                          New Topic
                        </span>
                      </IconButton>

                      <div
                        className="DR-Custom-Chat-input"
                        style={{
                          borderRadius: "30px",
                        }}
                      >
                        <div className="single-line w-100">
                          <MentionsInput
                            // singleLine
                            className="DR-bot-input-main"
                            value={input}
                            onChange={(
                              event,
                              newValue,
                              newPlainTextValue,
                              mentions
                            ) => {
                              setInput(newPlainTextValue);
                            }}
                            allowSuggestionsAboveCursor="true"
                            placeholder={"Write your question here..."}
                            onKeyDown={handleKeyDown}
                            style={defaultStyle}
                            disabled={loading}
                          >
                            <Mention
                              // data={renderPayerOptions()}
                              appendSpaceOnAdd
                              style={{
                                backgroundColor: "#cee4e5",
                                paddingTop: "3px",
                                paddingBottom: "3px",
                                borderRadius: "2px",
                              }}
                            />
                          </MentionsInput>
                        </div>

                        <button
                          onClick={handleSubmit}
                          className="DR-input-icon-color-Zoe"
                          disable={!input?.length}
                        >
                          <BootstrapTooltip title="Send Message">
                            <span>
                              {input?.length ? (
                                <CommentSendGreenZoe />
                              ) : (
                                <CommentSendIcon />
                              )}
                            </span>
                          </BootstrapTooltip>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <Grid
                      item
                      sm={12}
                      style={{
                        marginTop: "40px",
                      }}
                    >
                      <div
                        className="DR-Custom-Chat-input-outer SAM-bot-new-ui"
                        style={{
                          zIndex: "5",
                          backgroundColor: "#fff",
                          position: "static",
                          // maxWidth: "calc(100% - 650px)",
                        }}
                      >
                        <div
                          className="DR-Custom-Chat-input"
                          style={{
                            borderRadius: "5px",
                            marginLeft: "9px",
                            minWidth: "100%",
                          }}
                        >
                          <div className="single-line w-100">
                            <MentionsInput
                              // singleLine
                              className="DR-bot-input-main"
                              value={input}
                              onChange={(
                                event,
                                newValue,
                                newPlainTextValue,
                                mentions
                              ) => {
                                setInput(newPlainTextValue);
                              }}
                              allowSuggestionsAboveCursor="true"
                              placeholder={
                                "Describe the visual you want to create..."
                              }
                              onKeyDown={handleKeyDown}
                              style={defaultStyle}
                              disabled={loading}
                            >
                              <Mention
                                // data={renderPayerOptions()}
                                appendSpaceOnAdd
                                style={{
                                  backgroundColor: "#cee4e5",
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                  borderRadius: "2px",
                                }}
                              />
                            </MentionsInput>
                          </div>

                          <button
                            onClick={handleButtonClick}
                            className="DR-input-icon-color-Zoe"
                            disable={!input?.length}
                          >
                            <BootstrapTooltip title="Send Message">
                              <span>
                                {input?.length ? (
                                  <CommentSendGreenZoe />
                                ) : (
                                  <CommentSendIcon />
                                )}
                              </span>
                            </BootstrapTooltip>
                          </button>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>

              {mainOutput?.map((item, index) => {
                return (
                  <div key={index} className="w-100">
                    {item?.role === chatbotEnum?.bot ? (
                      <div
                        className="DR-Custom-Chat-msg-bot"
                        style={
                          item?.code
                            ? { marginBottom: "30px" }
                            : { marginBottom: "5px" }
                        }
                      >
                        <div
                          className="DR-Custom-Chat-msg-bot-inner"
                          style={{
                            background: "none",
                            maxWidth: "95%",
                            width: "100%",
                            padding: "0",
                            boxShadow: "none",
                          }}
                        >
                          {/* {item?.msg} */}
                          {handleSyntaxText(item?.msg, item)}
                          <div
                            className="DR-like-box DR-lida-action"
                            style={{ bottom: "-25px", left: "0" }}
                          >
                            {item?.code && (
                              <>
                                <button
                                  // disabled={index?.toString() === copiedItem}
                                  style={{
                                    background: "none",
                                    boxShadow: "none",
                                    border: "none",
                                    marginRight: "10px",
                                    cursor: "pointer",

                                    borderRadius: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  disabled={loading}
                                  onClick={() => {
                                    handleExplain(item);
                                  }}
                                >
                                  <ExplainIcon /> Explain
                                </button>
                                <Popover
                                  open={promptOpen === index ? true : false}
                                  onOpenChange={(newOpen) => {
                                    setPromptOpen("");
                                  }}
                                  content={
                                    <div>
                                      <div
                                        className="DR-Upload-Input"
                                        style={{
                                          minHeight: "150px",
                                          width: "400px",
                                        }}
                                      >
                                        <TextField
                                          className="DR-new-input-mui-lb DR-question-multiline"
                                          id="question"
                                          placeholder="Add Comment"
                                          name="question"
                                          variant="filled"
                                          fullWidth
                                          multiline
                                          maxRows={4}
                                          minRows={3}
                                          onChange={(e) => {
                                            setRepairMsg(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <Box
                                        className="mt-3"
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-end",
                                          justifyContent: "flex-end",
                                          height: "100%",
                                          gap: "12px",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          className="cancel-button"
                                          onClick={() => {
                                            closePrompt(index);
                                          }}
                                        >
                                          Cancel
                                        </Button>

                                        <Button
                                          variant="contained"
                                          type="submit"
                                          color="primary"
                                          style={{
                                            backgroundColor: "#67D091",
                                            color: "#ffffff",
                                          }}
                                          className={`ok-button ${
                                            loading || !repairMsg?.trim()
                                              ? "button-opacity"
                                              : ""
                                          }`}
                                          disabled={
                                            loading || !repairMsg?.trim()
                                          }
                                          onClick={() => {
                                            handleRepair(item);
                                          }}
                                        >
                                          Send
                                        </Button>
                                      </Box>
                                    </div>
                                  }
                                  title="Add Repair Comment"
                                  trigger="click"
                                  placement="topRight"
                                  overlayStyle={{ zIndex: "1600" }}
                                >
                                  {/* <button
                                  style={{
                                    background: "none",
                                    boxShadow: "none",
                                    border: "none",
                                    marginRight: "10px",
                                    cursor: "pointer",

                                    borderRadius: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  disabled={loading}
                                  onClick={() => {
                                    setPromptOpen(index);
                                  }}
                                >
                                  <RepairIcon /> Repair
                                </button> */}
                                </Popover>
                                {/* <button
                                  // disabled={index?.toString() === copiedItem}
                                  style={{
                                    background: "none",
                                    boxShadow: "none",
                                    border: "none",
                                    marginRight: "10px",
                                    cursor: "pointer",

                                    borderRadius: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  disabled={loading}
                                  onClick={() => {
                                    handleTabularVersion(item);
                                  }}
                                >
                                  <ExplainIcon /> Table
                                </button> */}
                              </>
                            )}
                          </div>
                          {item?.hitUrl && (
                            <div className="DR-chat-refrance mt-4">
                              <div>
                                Do you want to search this on Web?{" "}
                                <button
                                  className="DR-yes-no-btn"
                                  onClick={() => {
                                    notFoundLink(item);
                                  }}
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : item?.role === "loading" ? (
                      <div className="DR-Custom-Chat-msg-bot">
                        <div
                          className="DR-Custom-Chat-msg-bot-inner"
                          style={{
                            // background: "none",
                            maxWidth: "95%",
                            // padding: "0",
                            boxShadow: "none",
                          }}
                        >
                          <img
                            style={{ width: "50px", boxShadow: "none" }}
                            src={ChatbotLoading}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="DR-Custom-Chat-msg-user w-100"
                        style={{
                          alignItems: "flex-start",
                          margin: "10px 10px",
                          padding: "0",
                        }}
                      >
                        <div
                          className="flex items-center"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "5px",
                          }}
                        >
                          <div className="DR-chat-result-user">
                            {getUserName()}
                          </div>
                          <span>You</span>
                        </div>
                        <div
                          className="DR-Custom-Chat-msg-user-inner"
                          style={{
                            background: "none",
                            boxShadow: "none",
                            color: "#131722",
                            maxWidth: "75%",
                            padding: "0",
                          }}
                        >
                          {item?.msg}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <button style={{ cursor: "pointer" }} onClick={handleReset}>
          New UI
        </button> */}
        <DRGroupQuestions
          sampleQuestion={lunaSampleGroupQuestions}
          handleClick={drSampleQuestionClick}
          loading={loading}
          setOpenCollaps={setOpenCollaps}
          openCollaps={openCollaps}
        />
      </div>
    </div>
  );
};

export default withMainLayout(ChatBotResultNew);
