import { createSlice } from "@reduxjs/toolkit";

import { resetListingState } from "../filters/claimManagerFilters";
import {
  commonPagination,
  commonPaginationForPost,
  getMultiSelectFilterValueForApi,
  getUserId,
  returnLocationId,
} from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";

export const UploadPolicy = createSlice({
  name: "UploadPolicy",
  initialState: {
    loading: false,
    outputList: [],
    copilotOutputList: [],
    policyCopilotHistoryOutputList: [],
    urlOuputList: [],
    flags: {},
    isIndexRunning: false,
    totalCount: 0,
    urlCount: 0,
  },
  reducers: {
    successOutputList: (state, action) => {
      const data = action?.payload;

      if (data?.output?.length) {
        state.outputList = data?.output;
        state.totalCount = data?.output?.[0]?.totalCount;
      } else {
        state.totalCount = 0;
        state.outputList = [];
      }
      if (data?.flags?.length) {
        state.flags = data?.flags?.[0];
      } else {
        state.flags = {};
      }
      if (data?.isIndexRunning) {
        state.isIndexRunning = true;
      } else {
        state.isIndexRunning = false;
      }
    },
    successCustomCopilotOutputList: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        state.copilotOutputList = data;
      } else {
        state.copilotOutputList = [];
      }
    },
    successPolicyChatbotHistoryList: (state, action) => {
      const data = action?.payload;
      if (data?.chatTitles?.length) {
        state.policyCopilotHistoryOutputList = data?.chatTitles;
      } else {
        state.policyCopilotHistoryOutputList = [];
      }
    },
    successUrlOutputList: (state, action) => {
      const data = action?.payload;
      if (data?.output?.length) {
        state.urlOuputList = data?.output;
        state.urlCount = data?.output?.[0]?.totalCount;
      } else {
        state.totalCount = 0;
        state.urlOuputList = [];
      }
      if (data?.flags?.length) {
        state.flags = data?.flags?.[0];
      } else {
        state.flags = {};
      }
    },
    successIndexStatus: (state, action) => {
      const data = action.payload;
      state.isIndexRunning = data?.isIndexRunning ? true : false;
    },
  },
});

export const {
  successOutputList,
  successCustomCopilotOutputList,
  successPolicyChatbotHistoryList,
  successIndexStatus,
  successUrlOutputList,
} = UploadPolicy.actions;

export const getPolicyUploadOutputList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "UploadPolicyFilter",
        })
      );
    }
    const mainId = returnLocationId();
    let sliceStateUser = getState().pReducers.user;

    let sliceState = getState().npReducers.filters.UploadPolicyFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    //
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.UploadPolicyFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchFile: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),

        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchFile: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successOutputList,
        "UploadPolicyFilter",
        "GET_ALL_POLICY",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getPolicyUploadOutputList_WithId =
  (mainId) => async (dispatch, getState) => {
    // const mainId = returnLocationId();
    let sliceStateUser = getState().pReducers.user;

    let sliceState = getState().npReducers.filters.UploadPolicyFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.UploadPolicyFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchFile: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),

        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchFile: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successOutputList,
        "UploadPolicyFilter",
        "GET_ALL_POLICY",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getPolicyUploadOutputListWithTimeStamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "UploadPolicyFilter",
        })
      );
    }

    let sliceStateUser = getState().pReducers.user;

    let sliceState = getState().npReducers.filters.UploadPolicyFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    //
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.UploadPolicyFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    const mainId = returnLocationId();

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...{
          searchFile: sliceState.search,
        },
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),

        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...{
          searchFile: sliceState.search,
        },
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successOutputList,
        "UploadPolicyFilter",
        "GET_ALL_POLICY",
        null,
        "",
        ""
      );
      dispatch(checkIndexRunningStatus());
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getCopilotOutputListWithTimeStamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "CustomCopilotFilter",
        })
      );
    }

    let sliceState = getState().npReducers.filters.CustomCopilotFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        userId: getUserId(),
        timestamp: new Date().getTime(),
      };

      let params = {
        userId: getUserId(),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        params,
        paramsTemp,
        dispatch,
        successCustomCopilotOutputList,
        "CustomCopilotFilter",
        "GET_CUSTOM_COPILOT",
        null,
        "",
        ""
      );
      dispatch(checkIndexRunningStatus());
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getCopilotOutputList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "CustomCopilotFilter",
        })
      );
    }
    let sliceState = getState().npReducers.filters.CustomCopilotFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        userId: getUserId(),
      };

      let params = {
        userId: getUserId(),
      };

      commonPagination(
        getState,
        params,
        paramsTemp,
        dispatch,
        successCustomCopilotOutputList,
        "CustomCopilotFilter",
        "GET_CUSTOM_COPILOT",
        null,
        "",
        ""
      );
      dispatch(checkIndexRunningStatus());
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getCopilotOutputListTimestamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "CustomCopilotFilter",
        })
      );
    }
    let sliceState = getState().npReducers.filters.CustomCopilotFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        userId: getUserId(),
        timestamp: new Date().getTime(),
      };

      let params = {
        userId: getUserId(),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        params,
        paramsTemp,
        dispatch,
        successCustomCopilotOutputList,
        "CustomCopilotFilter",
        "GET_CUSTOM_COPILOT",
        null,
        "",
        ""
      );
      dispatch(checkIndexRunningStatus());
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getCopilotHistoryOutputList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "PolicyCopilotFilter",
        })
      );
    }

    let sliceState = getState().npReducers.filters.PolicyCopilotFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        userId: getUserId(),
      };

      let params = {
        userId: getUserId(),
      };

      commonPagination(
        getState,
        params,
        paramsTemp,
        dispatch,
        successPolicyChatbotHistoryList,
        "PolicyCopilotFilter",
        "GET_POLICY_CHAT_HISTORY",
        null,
        "",
        ""
      );
      dispatch(checkIndexRunningStatus());
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getCopilotHistoryOutputListTimestamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "PolicyCopilotFilter",
        })
      );
    }

    let sliceState = getState().npReducers.filters.PolicyCopilotFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        userId: getUserId(),
        timestamp: new Date().getTime(),
      };

      let params = {
        userId: getUserId(),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        params,
        paramsTemp,
        dispatch,
        successPolicyChatbotHistoryList,
        "PolicyCopilotFilter",
        "GET_POLICY_CHAT_HISTORY",
        null,
        "",
        ""
      );
      dispatch(checkIndexRunningStatus());
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getPolicyUrlOutputList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "UploadUrlFilter",
        })
      );
    }
    let sliceStateUser = getState().pReducers.user;

    let sliceState = getState().npReducers.filters.UploadUrlFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    //
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.UploadUrlFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    const mainId = returnLocationId();
    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchUrl: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchUrl: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successUrlOutputList,
        "UploadUrlFilter",
        "GET_ALL_POLICY_URL",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };
export const getPolicyUrlOutputList_WithId =
  (mainId) => async (dispatch, getState) => {
    let sliceStateUser = getState().pReducers.user;

    let sliceState = getState().npReducers.filters.UploadUrlFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.UploadUrlFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    // const mainId = returnLocationId();
    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());

      let paramsTemp = {
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchUrl: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...{
          searchUrl: sliceState.search,
        },
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(mainId && {
          copilotId: mainId,
        }),
        timestamp: new Date().getTime(),
      };

      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        successUrlOutputList,
        "UploadUrlFilter",
        "GET_ALL_POLICY_URL",
        null,
        "",
        ""
      );
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };

export const buildIndexFunc = () => {
  return genericAxiosCall(serverRoutes?.BUILD_INDEX, "post", "", "");
};
export const checkIndexRunningStatus =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    try {
      // dispatch(gettingSmartInsightReasonCodeHeatmap_Call());
      genericAxiosCall(serverRoutes?.CHECK_INDEX_STATUS, "get", "", "")
        ?.then((res) => {
          dispatch(successIndexStatus(res?.data?.data));
        })
        .catch((err) => {
          dispatch(successIndexStatus({}));
        });
    } catch (e) {
      // console.log("catch--->>", e);
    }
  };

export const IncrementalIndexFunc = () => {
  return genericAxiosCall(serverRoutes?.INCREMENTAL_INDEX, "post", "", "");
};
export const FullIndexFunc = () => {
  return genericAxiosCall(serverRoutes?.FULL_INDEX, "post", "", "");
};
export const RunIndexFunc = (flags) => {
  // return flags?.isFullIndexEnabled
  //   ? genericAxiosCall(serverRoutes?.FULL_INDEX, "post", "", "")
  //   : genericAxiosCall(serverRoutes?.INCREMENTAL_INDEX, "post", "", "");
  const params = {
    ...(returnLocationId() && {
      copilotId: returnLocationId(),
    }),
  };
  return genericAxiosCall(serverRoutes?.FULL_INDEX, "post", "", params);
};

export default UploadPolicy.reducer;
