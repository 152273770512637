import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ArrowUpMenu from "../../assets/svgs/ArrowUpMenu";
import ArrowDownMenu from "../../assets/svgs/ArrowDownMenu";
import { useSelector } from "react-redux";
import {
  botRoutes,
  moduleRolesEnum,
  returnLocationId,
} from "../../components/common/Utils/utils";
import {
  getCopilotOutputList,
  getCopilotOutputListWithTimeStamp,
} from "../../Redux/Reducers/UploadPolicy/UploadPolicySlice";
import { useDispatch } from "react-redux";
import FilterLoading from "../../components/common/Loading/SekeletonLoading/FilterLoading";
import browserRoute from "../../constants/browserRoutes";
import { useNavigate } from "react-router-dom";
import DRAvaChatIcon from "../../assets/svgs/DRAvaChatIcon";
import DRElgibilityBotIcon from "../../assets/svgs/DRElgibilityBotIcon";
import DRKnowledgeBaseIcon from "../../assets/svgs/DRKnowledgeBaseIcon";
import {
  COPILOT_NAME,
  ELIGIBILITY_COPILOT_BOTNAME,
  POLICY_COPILOT_BOTNAME,
} from "../../components/common/Utils/copilot";

export default function CopilotNavDropDown() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const mainId = searchParams.get("id");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { currentModule } = useSelector((state) => state.pReducers.user);
  //Copilot
  const { loginUserData } = useSelector((state) => state.pReducers.user);
  const { OrganizationName } = loginUserData;
  const defaultRoutes = [
    {
      value: browserRoute?.DOMAIN_COPILOT,
      label: `${OrganizationName} ${COPILOT_NAME}`,
      icon: <DRAvaChatIcon />,
      knowledgeBaseRoute: browserRoute?.UPLOAD_DOCOMENTS,
    },
    {
      value: browserRoute?.ASK_ZOE,
      label: POLICY_COPILOT_BOTNAME,
      icon: <DRAvaChatIcon />,
      // knowledgeBaseRoute: browserRoute?.UPLOAD_DOCOMENTS,
      multipleRoutes: [
        browserRoute?.ASK_ZOE?.split("/")?.[1],
        browserRoute?.ASK_ZOE_CHAT?.split("/")?.[1],
      ],
    },
    {
      value: browserRoute?.ASK_ROVERS,
      label: ELIGIBILITY_COPILOT_BOTNAME,
      icon: <DRElgibilityBotIcon />,
      // knowledgeBaseRoute: browserRoute?.UPLOAD_DOCOMENTS,
    },
  ];
  const { loadingMore } = useSelector(
    (state) => state.npReducers.filters.CustomCopilotFilter
  );
  const { copilotOutputList: outputList } = useSelector(
    (state) => state.npReducers.uploadPolicy
  );
  const [mainRoutes, setMainRoutes] = React.useState([]);
  const [routeName, setRouteName] = React.useState(null);
  //Copilot
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRoute = (route) => {
    nav(route?.value);
  };
  const setDefaultRoutes = () => {
    const routes = outputList?.map((item, index) => {
      return {
        value: `${browserRoute?.ASK_CUSTOM}?id=${item?.copilotId}&name=${item?.copilotName}`,
        label: item?.copilotName,
        icon: <DRAvaChatIcon />,
        id: item?.copilotId,
        knowledgeBaseRoute: `${browserRoute?.UPLOAD_DOCOMENTS}?id=${item?.copilotId}&name=${item?.copilotName}`,
      };
    });
    setMainRoutes([...defaultRoutes, ...routes]);
  };
  const knowledgeBaseRoute = (item) => {
    nav(item?.knowledgeBaseRoute);
  };

  const getRouteName = React.useMemo(() => {
    if (loadingMore) return "";

    const pathName = window.location.pathname?.split("/")?.[1];
    const completePathName = window.location.href;
    let routeName = "";

    mainRoutes?.forEach((item) => {
      const routeSplit = item?.value?.split("/")?.[1]?.split("?")?.[0];

      if (
        completePathName?.includes("?") &&
        pathName === browserRoute?.ASK_CUSTOM?.split("/")?.[1]
      ) {
        const id = returnLocationId();

        if (id == item?.id) {
          setRouteName(item);
          routeName = item?.label;

          return;
        }
      } else if (routeSplit === pathName) {
        setRouteName(item);
        routeName = item?.label;
        return;
      } else if (item?.multipleRoutes?.length) {
        if (item?.multipleRoutes?.includes(pathName)) {
          setRouteName(item);
          routeName = item?.label;
        }
      }
    });

    return routeName;
  }, [loadingMore, window.location, mainRoutes, mainId]);
  React.useEffect(() => {
    // if (
    //   currentModule?.value
    //     ?.toLowerCase()
    //     ?.includes(moduleRolesEnum?.Policy?.toLocaleLowerCase())
    // ) {
    //   dispatch(getCopilotOutputList());
    // }

    if (botRoutes?.includes(window.location.pathname)) {
      dispatch(getCopilotOutputList());
    }
  }, [currentModule]);

  React.useEffect(() => {
    if (!loadingMore) {
      setDefaultRoutes();
    }
  }, [loadingMore, outputList]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip>
          <Button
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            className="DR-Copliot-dropdown"
          >
            {loadingMore ? (
              <FilterLoading width={100} height={40} />
            ) : (
              <>
                {getRouteName} {open ? <ArrowUpMenu /> : <ArrowDownMenu />}
              </>
            )}
          </Button>
        </Tooltip>
      </Box>
      {!loadingMore && (
        <>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            className="DR-Moudual-drop-items"
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {loadingMore ? (
              <>
                <FilterLoading width={100} height={40} />
              </>
            ) : (
              mainRoutes?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <MenuItem
                      style={{
                        backgroundColor:
                          item?.label === routeName?.label
                            ? "rgba(0, 0, 0, 0.04)"
                            : "",
                      }}
                    >
                      <span className="w-100 flex items-center justify-between">
                        <span
                          className="flex items-center DR-mod-lable-outer"
                          onClick={() => {
                            handleRoute(item);
                          }}
                        >
                          <span className="DR-mod-llm-icon flex">
                            {item?.icon}
                          </span>

                          <span>{item?.label}</span>
                        </span>
                        <span className="DR-inner-arrow">
                          {item?.knowledgeBaseRoute && <ArrowUpMenu />}
                        </span>
                      </span>
                      {item?.knowledgeBaseRoute && (
                        <Button
                          className="DR-new-copilot-btn DR-copilot-fltout"
                          onClick={() => {
                            knowledgeBaseRoute(item);
                          }}
                        >
                          <DRKnowledgeBaseIcon />
                          Knowledge Base
                        </Button>
                      )}
                    </MenuItem>
                    {/* {index !== mainRoutes?.length - 1 && <Divider />} */}
                  </React.Fragment>
                );
              })
            )}
          </Menu>
          {/* <Button className="DR-new-copilot-btn" onClick={knowledgeBaseRoute}>
            Knowledge Base
          </Button> */}
        </>
      )}
    </React.Fragment>
  );
}
