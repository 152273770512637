import React from "react";

const DRReminderIcon = () => {
  return (
    // <svg
    //   width="24"
    //   height="24"
    //   viewBox="0 0 24 24"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M3.55469 10.1111C3.55469 10.9362 3.88246 11.7276 4.46591 12.311C5.04936 12.8944 5.84068 13.2222 6.6658 13.2222C7.49092 13.2222 8.28224 12.8944 8.86569 12.311C9.44913 11.7276 9.77691 10.9362 9.77691 10.1111C9.77691 9.28599 9.44913 8.49467 8.86569 7.91122C8.28224 7.32778 7.49092 7 6.6658 7C5.84068 7 5.04936 7.32778 4.46591 7.91122C3.88246 8.49467 3.55469 9.28599 3.55469 10.1111Z"
    //     stroke="#07A784"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M2 20.9997V19.4441C2 18.619 2.32778 17.8277 2.91122 17.2442C3.49467 16.6608 4.28599 16.333 5.11111 16.333H8.22222C9.04734 16.333 9.83866 16.6608 10.4221 17.2442C11.0056 17.8277 11.3333 18.619 11.3333 19.4441V20.9997"
    //     stroke="#07A784"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M12.1113 7.10156C12.7805 7.27291 13.3737 7.66211 13.7973 8.2078C14.2208 8.7535 14.4508 9.42465 14.4508 10.1155C14.4508 10.8063 14.2208 11.4774 13.7973 12.0231C13.3737 12.5688 12.7805 12.958 12.1113 13.1293"
    //     stroke="#07A784"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M15.9993 21.0002V19.4446C15.9954 18.758 15.7644 18.0919 15.3423 17.5503C14.9203 17.0086 14.3309 16.6219 13.666 16.4502"
    //     stroke="#07A784"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    //   <path
    //     d="M17 5.24265H22L20.3929 3.62132L22 2H17V9"
    //     stroke="#E04531"
    //     stroke-linecap="round"
    //     stroke-linejoin="round"
    //   />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#E04531"
      stroke-linecap="round"
      stroke-linejoin="round"
      width="24"
      height="24"
      stroke-width="1"
    >
      {" "}
      <path d="M5 14h14l-4.5 -4.5l4.5 -4.5h-14v16"></path>{" "}
    </svg>
  );
};

export default DRReminderIcon;
