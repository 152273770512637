import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";

import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import {
  commonPagination,
  commonPaginationWithTimeStamp,
  getCurrentClientId,
} from "../../../components/common/Utils/utils";
import { setListingState } from "../filters/claimManagerFilters";
import store from "../../store";

export const validator = (r) => !!r.value;

export const AlertCenterSlice = createSlice({
  name: "AlertCenterSlice",
  initialState: {
    alertOutputList: [],
    inboxOutputList: [],
    inboxDetailOutput: {},
    archiveOutputList: [],
    querylookuploading: true,
    rowsCount: 0,
    inboxRowCount: 0,
    archiveRowCount: 0,
    querylookupData: {
      fieldsFor835: [
        { name: "DOS", label: "Date of Service", inputType: "date", validator },
        {
          name: "ClaimStatusCodeId",
          label: "Claim Status",
          valueEditorType: "multiselect",
          values: [
            // { name: "ClaimStatus1", label: "Claim Status 1" },
            // { name: "ClaimStatus2", label: "Claim Status 2" },
            // { name: "ClaimStatus3", label: "Claim Status 3" },
          ],
          validator,
        },
        {
          name: "FilingIndicatorCodeId",
          label: "Filing Indicator Code",
          valueEditorType: "select",
          values: [
            // { name: "FilingIndicatorCode1", label: "Filing Indicator Code 1" },
            // { name: "FilingIndicatorCode2", label: "Filing Indicator Code 2" },
            // { name: "FilingIndicatorCode3", label: "Filing Indicator Code 3" },
          ],
          validator,
        },
        {
          name: "PayeeId",
          label: "Provider ",
          valueEditorType: "select",
          values: [
            // { name: "PayeeID1", label: "PayeeId 1" },
            // { name: "PayeeID2", label: "PayeeId 2" },
            // { name: "PayeeID3", label: "PayeeId 3" },
          ],
          validator,
        },
        {
          name: "PayerId",
          label: "Payer",
          valueEditorType: "select",
          values: [
            // { name: "PayerID1", label: "PayerId 1" },
            // { name: "PayerID2", label: "PayerId 2" },
            // { name: "PayerID3", label: "PayerId 3" },
          ],
          validator,
        },
        {
          name: "ChargeAmount",
          label: "Charge Amount",
          inputType: "number",
          validator,
        },

        {
          name: "AllowedAmount",
          label: "Allowed Amount",
          inputType: "number",
          validator,
        },
        {
          name: "PaymentAmount",
          label: "Payment Amount",
          inputType: "number",
          validator,
        },
        {
          name: "AdjudicatedProcedureCode1",
          label: "Procedure Code",
          validator,
        },
        {
          name: "ReasonCode",
          label: "Reason Code",
          validator,
        },
        {
          name: "IPRCPaymentRemarkCode",
          label: "Payment Remark Code IPRC",
          validator,
        },
        {
          name: "OPRCPaymentRemarkCode",
          label: "Payment Remark Code OPRC",
          validator,
        },

        {
          name: "MLCPMLProbability",
          label: "AI Probability",
          inputType: "number",
          validator,
        },
        {
          name: "MLCPMLExpectedPayout",
          label: "Expected Payout",
          inputType: "number",
          validator,
        },
        {
          name: "CPMedicalRecordNo",
          label: "Medical Record Number",
          validator,
        },
        {
          name: "CPPrincipalDiagCode",
          label: "Diagnosis Code",
          valueEditorType: "select",
          validator,
        },
      ],
      fieldsFor837: [
        { name: "DOS", label: "Date of Service", inputType: "date", validator },
        {
          name: "PayerId",
          label: "Payer",
          valueEditorType: "select",
          values: [
            // { name: "PayerID1", label: "PayerId 1" },
            // { name: "PayerID2", label: "PayerId 2" },
            // { name: "PayerID3", label: "PayerId 3" },
          ],
          validator,
        },
        {
          name: "ChargeAmount",
          label: "Billed Amount",
          inputType: "number",
          validator,
        },
        {
          name: "MLServiceLinePer",
          label: "Approval Prediction",
          inputType: "number",
          validator,
        },
        {
          name: "CRPhysicianId",
          label: "Physician NPI",
          // inputType: "number",
          validator,
        },
        {
          name: "CRMedicalRecordNo",
          label: "Medical Record Number",
          // inputType: "number",
          validator,
        },
        {
          name: "CRRepricedAllowedAmount",
          label: "Allowed Amount",
          inputType: "number",
          validator,
        },
        {
          name: "CRBillingProviderId",
          label: "Provider",
          // inputType: "number",
          validator,
        },
        {
          name: "MLCRPApprovedPer",
          label: "Charge Amount Prediction",
          inputType: "number",
          validator,
        },
        {
          name: "CRFrequencyCode",
          label: "Frequency Code",
          inputType: "number",
          validator,
        },
        {
          name: "SRProcedureCode",
          label: "Procedure Code",
          // inputType: "number",
          validator,
        },
      ],
    },
  },
  reducers: {
    setAlertOutputList: (state, action) => {
      let data = action.payload;
      if (data?.length > 0) {
        state.alertOutputList = data;
        state.rowsCount = data[0]?.rowsCount;
      } else {
        state.alertOutputList = [];
        state.rowsCount = 0;
      }
    },
    setInboxOutputList: (state, action) => {
      let data = action.payload;
      if (data?.length > 0) {
        state.inboxOutputList = data;
        state.inboxRowCount = data[0]?.totalCount;
      } else {
        state.inboxOutputList = [];
        state.inboxRowCount = 0;
      }
    },
    setArchiveOutputList: (state, action) => {
      let data = action.payload;
      if (data?.length > 0) {
        state.archiveOutputList = data;
        state.archiveRowCount = data[0]?.totalCount;
      } else {
        state.archiveOutputList = [];
        state.archiveRowCount = 0;
      }
    },
    setInboxDetailOutputList: (state, action) => {
      let data = action.payload;
      if (data) {
        state.inboxDetailOutput = data;
      } else {
        state.inboxDetailOutput = {};
      }
    },
    SetQueryLookupLoading: (state, action) => {
      state.querylookuploading = action.payload;
    },

    setQueryLookupData: (state, action) => {
      let data = action.payload;

      let tempState = state.querylookupData;

      const transType = data?.TransactionTypes?.map((item) => {
        return {
          label: item?.transactionTypeName,
          value: item?.transactionTypeID,
        };
      });
      state.transactionTypeListData = transType;

      //  ClaimStatusCode arry handle here
      if (data?.ClaimStatusCode && data?.ClaimStatusCode?.length > 0) {
        let newArr = [];
        data?.ClaimStatusCode.forEach((item) => {
          newArr.push({
            name: item?.claimStatusCodeId,
            label: item?.description,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "ClaimStatusCodeId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }
      //  FilingIndicatorCode arry handle here
      if (data?.FilingIndicatorCode && data?.FilingIndicatorCode?.length > 0) {
        let newArr = [];
        data?.FilingIndicatorCode.forEach((item) => {
          newArr.push({
            name: item?.filingIndicatorCodeId,
            label: item?.description,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "FilingIndicatorCodeId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }
      //  Payee arry handle here
      if (data?.Payee && data?.Payee?.length > -1) {
        let newArr = [];
        data?.Payee.forEach((item) => {
          newArr.push({
            name: item?.payeeId,
            // label: item?.name,
            label: `${item?.name}${
              item?.primaryId ? ` - ${item?.primaryId}` : ""
            }`,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "PayeeId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }
      //  Payer arry handle here
      if (data?.Payer && data?.Payer?.length > 0) {
        let newArr = [];
        data?.Payer.forEach((item) => {
          newArr.push({
            name: item?.payerId,
            label: `${item?.name}${
              item?.primaryId ? ` - ${item?.primaryId}` : ""
            }`,
          });
        });
        tempState.fieldsFor835.map((item) => {
          if (item.name === "PayerId") {
            item.values = newArr;
          }
        });
        tempState.fieldsFor837.map((item) => {
          if (item.name === "PayerId") {
            item.values = newArr;
          }
        });
      } else {
        // do nothing
      }

      //  User arry handle here
      if (data?.User && data?.User?.length > 0) {
        let newArr = [];
        data?.User.forEach((item) => {
          newArr.push({
            value: `${item?.displayName} - ${item?.userType} User`,
            label: `${item?.displayName} - ${item?.userType} User`,
            userId: item?.userId,
          });
        });
        state.userListArr = newArr;
      } else {
        state.userListArr = [];
      }
      //  DenialReasonCategories arry handle here
      if (
        data?.DenialReasonCategories &&
        data?.DenialReasonCategories?.length > 0
      ) {
        let newArr = [];
        data?.DenialReasonCategories.forEach((item) => {
          newArr.push({
            value: item?.category,
            label: item?.category,
          });
        });
        state.categoryListArr = newArr;
      } else {
        state.categoryListArr = [];
      }
    },
  },
});

export const {
  setAlertOutputList,
  setInboxOutputList,
  setArchiveOutputList,
  setInboxDetailOutputList,
  SetQueryLookupLoading,
  setQueryLookupData,
} = AlertCenterSlice.actions;

// get alert list
export const gettingAlertOutputListAfterCreation =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.alertDashboardFilter;

    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceStateUser = getState().pReducers.user;

    const { userId } = getState().pReducers.user.loginUserData;

    let start = Math.abs(sliceState.pageNumber);
    // let limit = Math.abs(sliceState.pageSize);
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.alertDashboardFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        userId: userId,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
        timestamp: new Date().getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        userId: userId,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
        timestamp: new Date().getTime(),
      };
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setAlertOutputList,
        "alertDashboardFilter",
        "GET_ALERT_DASHBOARD_SCREEN",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
export const gettingAlertOutputList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.alertDashboardFilter;

    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    const { userId } = getState().pReducers.user.loginUserData;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.alertDashboardFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        userId: userId,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        userId: userId,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setAlertOutputList,
        "alertDashboardFilter",
        "GET_ALERT_DASHBOARD_SCREEN",
        null
      );
    } catch (err) {
      console.log("err--->>", err);
    }
  };
export const gettingInboxOutputList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.inboxDashboardFilter;
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.alertInboxDashboardFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        isReadArr: sliceState?.isReadArr,
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPaginationWithTimeStamp(
        getState,
        paramsTemp,
        params,
        dispatch,
        setInboxOutputList,
        "inboxDashboardFilter",
        "GET_INBOX_DASHBOARD_SCREEN",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
export const gettingInboxOutputListAfterCreation =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.inboxDashboardFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        timestamp: new Date()?.getTime(),
      };

      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        timestamp: new Date()?.getTime(),
      };
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setInboxOutputList,
        "inboxDashboardFilter",
        "GET_INBOX_DASHBOARD_SCREEN",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
export const gettingArchiveOutputList =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceStateUser = getState().pReducers.user;
    let sliceState = getState().npReducers.filters.archiveDashboardFilter;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.alertArchiveDashboardFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        isArchive: true,
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        timestamp: new Date().getTime(),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        isArchive: true,
        ...(sliceState.order !== "" && {
          order: sliceState?.order,
        }),
        ...(sliceState.order !== "" && {
          orderBy: sliceState?.orderBy,
        }),
        timestamp: new Date().getTime(),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForClientId?.clientId) &&
            sliceStateForClientId?.clientId.length > 0
              ? sliceStateForClientId?.clientId[0].value
              : sliceStateForClientId?.clientId || getCurrentClientId(),
        }),
      };
      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setArchiveOutputList,
        "archiveDashboardFilter",
        "GET_INBOX_DASHBOARD_SCREEN",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
// get alert by id
export const gettingAlertById = (id, userId) => async (dispatch, getState) => {
  // = async (id, userId) => {

  try {
    let sliceStateUser = getState().pReducers.user;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    const params = {
      alertId: id,
      timestamp: new Date().getTime(),
      userId: userId,
      ...(sliceStateUser?.loginUserData?.isRcm && {
        clientId:
          Array.isArray(sliceStateForClientId?.clientId) &&
          sliceStateForClientId?.clientId.length > 0
            ? sliceStateForClientId?.clientId[0].value
            : sliceStateForClientId?.clientId || getCurrentClientId(),
      }),
    };

    // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
    const res = await genericAxiosCall(
      `${serverRoutes?.GET_ALERT_DASHBOARD_SCREEN_BY_ID}`,
      "get",
      "",
      params
    );
    if (res?.data?.success) {
      return res?.data?.data;

      //   dispatch(setQueryLookupData(res?.data?.data));
    } else {
      throw new Error("Something went wrong");
    }
  } catch (e) {
    console.log("e--->>", e);
    // throw new Error(e);
  }
};
//inbox details by id
export const gettingInboxById = async (id) => {
  try {
    const params = {
      AlertSummaryId: id.toString(),
      start: 0,
      limit: 10,
    };
    // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
    const res = await genericAxiosCall(
      `${serverRoutes?.GET_INBOX_DETAIL_DASHBOARD_SCREEN}`,
      "get",
      "",
      params
    );
    if (res?.data?.success) {
      return res?.data?.data;

      //   dispatch(setQueryLookupData(res?.data?.data));
    } else {
      throw new Error("Something went wrong");
    }
  } catch (e) {
    throw new Error(e);
    // console.log("e--->>", e);
  }
};
export const moveToArchive =
  (arr, handleClose) => async (dispatch, getState) => {
    const params = { AlertsSummaryIds: arr?.toString() };
    try {
      await genericAxiosCall(
        `${serverRoutes.MOVE_TO_ARCHIVE}`,
        "put",
        "",
        params
      )
        .then((res) => {
          if (res?.data?.success) {
            handleClose();
            toast.success("Moved to Archive successfully", {
              position: toast.POSITION.TOP_CENTER,
            });
            dispatch(gettingInboxOutputListAfterCreation());
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      // dispatch(SetQueryLookupLoading(true));
      // await genericAxiosCall(
      //   `${serverRoutes?.GET_ALL_LOOKUP_LIST}`,
      //   "post",
      //   "",
      //   ""
      // )
      //   .then((res) => {
      //     if (res?.data?.success) {
      //       dispatch(setQueryLookupData(res?.data?.data));
      //     } else {
      //       dispatch(setQueryLookupData([]));
      //     }
      //     return res;
      //   })
      //   .catch((err) => {
      //     // console.log("err--->>", err);
      //   });
    } catch (error) {
      // console.log("error--->>", error);
    } finally {
      dispatch(SetQueryLookupLoading(false));
    }
  };
export const markAsRead = (arr) => async (dispatch, getState) => {
  const params = { AlertsSummaryIds: arr?.toString() };
  try {
    await genericAxiosCall(`${serverRoutes.MARK_AS_READ}`, "put", "", params)
      .then((res) => {
        if (res?.data?.success) {
          // toast.success("Moved to Archive successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          // dispatch(gettingInboxOutputListAfterCreation());
        } else {
          // toast.error("Something went wrong", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        }
      })
      .catch((err) => {
        // toast.error(err?.response?.data.message, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      });
  } catch (error) {
    // console.log("error--->>", error);
  } finally {
    dispatch(SetQueryLookupLoading(false));
  }
};
export const markAsReadNotification = (arr) => async (dispatch, getState) => {
  const { userId } = getState().pReducers.user.loginUserData;
  const params = { NotificationIds: arr?.toString(), UserId: userId };
  try {
    await genericAxiosCall(
      `${serverRoutes.MARK_AS_READ_NOTIFICATION}`,
      "put",
      "",
      params
    )
      .then((res) => {
        if (res?.data?.success) {
          // toast.success("Moved to Archive successfully", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          // dispatch(gettingInboxOutputListAfterCreation());
        } else {
          // toast.error("Something went wrong", {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        }
      })
      .catch((err) => {
        // toast.error(err?.response?.data.message, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      });
  } catch (error) {
    // console.log("error--->>", error);
  } finally {
    dispatch(SetQueryLookupLoading(false));
  }
};
export const gettingInboxByIdEvent = (id) => async (dispatch, getState) => {
  let sliceState = getState().npReducers.filters.alertDashboardDetailsFilter;
  let sliceStateUser = getState().pReducers.user;

  let start = sliceState.pageNumber;
  // let limit = sliceState.pageSize;

  let limit =
    sliceStateUser?.globalObjNoOfRecordInTable
      ?.alertDashboardDetailsFilterNoOfRecordInTable;

  if (start >= 2) {
    let rec_val = (start - 1) * limit;
    start = rec_val;
  }
  if (start == 1) {
    start = 0;
  }

  try {
    let paramsTemp = {
      AlertSummaryId: id.toString(),
      ...(sliceState.order !== "" && {
        order: sliceState?.order,
      }),
      ...(sliceState.order !== "" && {
        orderBy: sliceState?.orderBy,
      }),
    };

    let params = {
      start,
      limit,
      AlertSummaryId: id.toString(),
      ...(sliceState.order !== "" && {
        order: sliceState?.order,
      }),
      ...(sliceState.order !== "" && {
        orderBy: sliceState?.orderBy,
      }),
    };
    // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
    commonPagination(
      getState,
      paramsTemp,
      params,
      dispatch,
      setInboxDetailOutputList,
      "alertDashboardDetailsFilter",
      "GET_INBOX_DETAIL_DASHBOARD_SCREEN",
      null
    );
  } catch (e) {
    // console.log("e--->>", e);
  }
};
// get all lookup list
export const gettingAllLookUptData_Call = () => async (dispatch, getState) => {
  try {
    dispatch(SetQueryLookupLoading(true));
    await genericAxiosCall(
      `${serverRoutes?.GET_ALL_LOOKUP_LIST_ALERT}`,
      "get",
      "",
      ""
    )
      .then((res) => {
        if (res?.data?.success) {
          dispatch(setQueryLookupData(res?.data?.data));
        } else {
          dispatch(setQueryLookupData([]));
        }
        return res;
      })
      .catch((err) => {
        // console.log("err--->>", err);
      });
  } catch (error) {
    // console.log("error--->>", error);
  } finally {
    dispatch(SetQueryLookupLoading(false));
  }
};

//Add New Alert
export const AddNewAlert_Call =
  (obj, handleClose) => async (dispatch, getState) => {
    try {
      await genericAxiosCall(
        `${serverRoutes.SAVE_ALERT_DASHBOARD_SCREEN}`,
        "post",
        obj,
        ""
      )
        .then((res) => {
          if (res?.data?.success) {
            handleClose();
            toast.success("Alert saved successfully", {
              position: toast.POSITION.TOP_CENTER,
            });
            dispatch(gettingAlertOutputListAfterCreation());
            return res;
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
          return res;
        })
        .catch((err) => {
          toast.error(err?.response?.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (error) {
      // toast.error(err?.response?.data.message, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      return error;

      // console.log("error--->>", error);
    } finally {
      dispatch(SetQueryLookupLoading(false));
    }
  };
//Delete Alert
export const DeleteAlert_Call =
  (id, handleClose, count, pageNumber, list, clientId) =>
  async (dispatch, getState) => {
    try {
      const params = { alertId: id, clientId: clientId };
      const res = await genericAxiosCall(
        `${serverRoutes.DELETE_ALERT_DASHBOARD_SCREEN}`,
        "delete",
        "",
        params
      );
      if (res?.data?.success) {
        handleClose();
        toast.success("Alert Deleted successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        if (list?.length == 1) {
          await dispatch(
            setListingState({
              listing: "alertDashboardFilter",
              value: {
                pageNumber: pageNumber - 1 === 0 ? 0 : pageNumber - 1,
              },
            })
          );
        }
        dispatch(gettingAlertOutputListAfterCreation());
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      // console.log("error--->>", error);
      // toast.error(error?.response?.data.message, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    } finally {
      dispatch(SetQueryLookupLoading(false));
    }
  };
// To Activate Or Deactivate Alert
export const ActivateDeactivateAlert =
  (obj, setLoading) => async (dispatch, getState) => {
    try {
      await genericAxiosCall(
        `${serverRoutes.SAVE_ALERT_DASHBOARD_SCREEN}`,
        "post",
        obj,
        ""
      )
        .then((res) => {
          if (res?.data?.success) {
            dispatch(gettingAlertOutputListAfterCreation());
          } else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
            throw new Error("Something went wrong");
          }
        })
        .catch((err) => {
          // toast.error(err?.response?.data.message, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          throw new Error(err);
        });
    } catch (error) {
      throw new Error(err);

      // console.log("error--->>", error);
    } finally {
      setLoading("");
      dispatch(SetQueryLookupLoading(false));
    }
  };
export default AlertCenterSlice.reducer;
