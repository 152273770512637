import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "../../assets/svgs/CloseIcon";
import { FormHelperText, Grid, TextField } from "@mui/material";
import PlusIcon from "../../assets/svgs/PlusIcon";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TagsDropdown from "../../pages/CustomProcedureCode/TagsDropdown";
import genericAxiosCall from "../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../constants/serverRoutes";
import FilterLoading from "../common/Loading/SekeletonLoading/FilterLoading";
import {
  ErrorToast,
  ErrorToastMsg,
  getterRoleBasedModules,
  getUserId,
  SuccessToast,
  UnSuccessfullToast,
} from "../common/Utils/utils";
import { useDispatch } from "react-redux";
import {
  getCopilotOutputListWithTimeStamp,
  getPolicyUploadOutputList_WithId,
  getPolicyUrlOutputList_WithId,
} from "../../Redux/Reducers/UploadPolicy/UploadPolicySlice";
import { environment } from "../../constants/serverRoutes";
import { COPILOT_NAME } from "../common/Utils/copilot";

const steps = ["Create Name", "Select User"];

export default function NewCustomCopilot() {
  const initialInput = {
    name: "",
    description: "",
    users: [],
  };
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [inputs, setInputs] = React.useState(initialInput);
  const [error, setError] = React.useState(initialInput);
  const [loading, setLoading] = React.useState(false);
  // Steps
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [userLoading, setUserLoading] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const [usersList, setUsersList] = React.useState([]);
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleClose = () => {
    setActiveStep(0);
    setOpen(false);
    setInputs(initialInput);
  };
  const getAllUsers = () => {
    setUserLoading(true);
    genericAxiosCall(serverRoutes?.GET_ALL_ORGANISATION_USERS, "get", "", "")
      .then((res) => {
        if (res?.data?.success) {
          const data = res?.data?.data;
          const arr = data?.map((item) => {
            return {
              label: `${item?.displayName}${
                item?.userType && ` - ${item?.userType}`
              }`,

              value: item?.userId,
            };
          });
          setUsersList(arr);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setUserLoading(false);
      });
  };
  const validateName = (name) => {
    // Regex to allow letters, numbers, hyphens, underscores, and spaces
    const namePattern = /^[a-zA-Z0-9-\s]+$/;
    return namePattern.test(name);
  };

  const handleNext = () => {
    const name = inputs?.name?.trim();
    let newSkipped = skipped;

    if (!name || !validateName(name)) {
      // Show error toast if name is invalid
      ErrorToastMsg("Name can only contain letters, numbers, and hyphens.");
      return; // Exit the function
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCreate = () => {
    setSubmitLoading(true);
    const body = {
      CopilotName: inputs?.name?.trim(),
      CreatedBy: getUserId(),
      SharedWithUserIds: inputs?.users,
    };

    genericAxiosCall(serverRoutes?.CREATE_CUSTOM_COPILOT, "post", body, "")
      .then((res) => {
        if (res?.data?.success) {
          SuccessToast(res);
          dispatch(getCopilotOutputListWithTimeStamp());
          dispatch(
            getPolicyUploadOutputList_WithId(res?.data?.data?.copilotId)
          );
          dispatch(getPolicyUrlOutputList_WithId(res?.data?.data?.copilotId));
          handleClose();
        } else {
          UnSuccessfullToast(res);
        }
      })
      .catch((err) => {
        ErrorToast(err);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const handleDisable = () => {
    let disabled = false;
    if (activeStep === 0) {
      if (!inputs?.name) {
        disabled = true;
      }
    } else if (activeStep === 1) {
      if (!inputs?.users?.length) {
        disabled = true;
      }
    }

    return disabled;
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const checkForCustomCopilot = () => {
    return getterRoleBasedModules()?.some(
      (module) => module.moduleName === "Custom Copilot"
    );
  };
  const handleChange = (e) => {
    const nameLimit = 60;
    const { name, value } = e.target;
    if (name === "name" && value?.length > nameLimit) {
      return;
    }
    setInputs({
      ...inputs,
      [name]: value,
    });
    setError({
      ...error,
      [name]: "",
    });
  };

  React.useEffect(() => {
    if (open) {
      getAllUsers();
    }
  }, [open]);
  React.useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);
  return (
    <div>
      {checkForCustomCopilot() && (
        <Button className="DR-new-copilot-btn" onClick={handleOpen}>
          <PlusIcon /> New {COPILOT_NAME}
        </Button>
      )}
      <Modal
        open={open}
        // onClose={handleCloseBtn}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ zIndex: "1615" }}
      >
        <div
          className="newModal"
          style={{ minWidth: "650px", maxWidth: "650px" }}
        >
          <div className="custom-modal">
            <div className="newModalHeader">
              <div className="newModalHeaderTitle">
                <h3 className="headerTitle" id="simple-modal-title">
                  New {COPILOT_NAME}
                </h3>
                <div className="d-flex">
                  <div className="headerCloseIcon" onClick={handleClose}>
                    <CloseIcon />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="newModalBody"
              style={{
                marginTop: "0",
                padding: "15px 15px 20px",
              }}
            >
              <Box className="DR-Apeal-steps" sx={{ width: "100%" }}>
                <Stepper
                  activeStep={activeStep}
                  style={{
                    maxWidth: "400px",
                    margin: "0 auto",
                    marginBottom: "10px",
                  }}
                >
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Copilot Created.
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 && (
                      <div
                        className="DR-Upload-Input"
                        style={{ minHeight: "190px" }}
                      >
                        <div>
                          <lable className="vel-input-lable">Name</lable>
                          <TextField
                            className="DR-new-input-mui-lb DR-que-input"
                            id="name"
                            placeholder={`${COPILOT_NAME} Name`}
                            value={inputs?.name}
                            onChange={handleChange}
                            name="name"
                            variant="filled"
                            fullWidth
                          />
                        </div>

                        <div className="mt-3">
                          <lable className="vel-input-lable">Description</lable>
                          <TextField
                            className="DR-new-input-mui-lb DR-question-multiline"
                            id="question"
                            placeholder="Description"
                            name="description"
                            value={inputs?.description}
                            onChange={handleChange}
                            variant="filled"
                            fullWidth
                            multiline
                            maxRows={3}
                            minRows={3}
                          />
                        </div>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <lable className="vel-input-lable">
                            Select Users *
                          </lable>
                          {userLoading ? (
                            <FilterLoading width={200} height={30} />
                          ) : (
                            <>
                              <div className="DR-new-pilot-tag">
                                <TagsDropdown
                                  setInputs={setInputs}
                                  inputs={inputs}
                                  mode="multiple"
                                  fieldName="users"
                                  data={usersList?.length ? usersList : []}
                                  setError={setError}
                                  error={error}
                                />
                              </div>
                              {error?.npiTags && (
                                <FormHelperText error>
                                  *User is required
                                </FormHelperText>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              </Box>

              <div className="flex justify-end">
                {!activeStep == 0 && (
                  <Button
                    variant="contained"
                    className="cancel-button"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    style={{ marginTop: 22, marginRight: 10 }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  onClick={
                    activeStep === steps.length - 1 ? handleCreate : handleNext
                  }
                  className={`dr-success-btn ${
                    submitLoading ? "loading" : "DR-add-role-btn"
                  } ${handleDisable() ? "button-opacity" : ""}`}
                  disabled={handleDisable()}
                  style={{ marginTop: 22 }}
                  size="small"
                >
                  {activeStep === steps.length - 1 ? "Create" : "Next"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
