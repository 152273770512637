import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
} from "../filters/claimManagerFilters";
import { commonPagination } from "../../../components/common/Utils/utils";
import { getMultiSelectFilterValueForApi } from "./../../../components/common/Utils/utils";

export const DenialsCategoriesConfigSlice = createSlice({
  name: "DenialsCategoriesConfigSlice",
  initialState: {
    outputList: [],
  },
  reducers: {
    setDenialsConfigTabular_data: (state, action) => {
      let data = action.payload;
      if (data?.length) {
        state.outputList = data;
      } else {
        state.outputList = [];
      }
    },
  },
});

export const { setDenialsConfigTabular_data } =
  DenialsCategoriesConfigSlice.actions;

// get activity logs list
export const gettingDenialsConfigList_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.denialsConfigFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.denialsConfigFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialsConfigTabular_data,
        "denialsConfigFilter",
        "GET_DENIAL_CONFIG_ALL_LIST",
        false,
        "",
        ""
      );

      ///// API CALL //////
    } catch (e) {
      console.log("e--->>", e);
    }
  };

export const gettingDenialsConfigList_Call_Timestamp =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.denialsConfigFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.denialsConfigFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start === 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
        timestamp: Date.now(),
      };
      let params = {
        start,
        limit,
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
        timestamp: Date.now(),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialsConfigTabular_data,
        "denialsConfigFilter",
        "GET_DENIAL_CONFIG_ALL_LIST",
        false,
        "",
        ""
      );

      ///// API CALL //////
    } catch (e) {
      console.log("e--->>", e);
    }
  };

export default DenialsCategoriesConfigSlice.reducer;
