import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import DataSource from "../../../actions/dataSource";
import { serverRoutes } from "../../../constants/serverRoutes";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";

import {
  setListingState,
  resetListingState,
  resetListingStateForResetButton,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  commonPaginationWithCatch,
  convertServiceLineToQueryFullPath,
  getCurrentClientId,
  getMultiSelectFilterIdForApi_ForId,
  getMultiSelectFilterValueForApi,
  getTreeNodeObj,
} from "../../../components/common/Utils/utils";
import { getClaimAllFiltersData_Call } from "./claimAllFiltersSlice";

export const denialsByCptScreenSlice = createSlice({
  name: "denialsByCptScreenSlice",
  initialState: {
    denialsByCpt_dataArr: { count: 0, totalChargeAmount: 0, output: [] },
    drugAccRate_data: {
      count: 0,
      totalChargeAmount: 0,
      tabularArr: [],
      serviceLineFilters: [],
      oncologyPreSelect: [],
      serviceLineFilterArr: [],
    },
    drugAccRateDetailTable_data: {
      firstPass: [],
      firstPassStatus: [],
      firstPassBilledTabularArr: [],
      secondPassBilledTabularArr: [],
      secondPass: [],
      secondPassStatus: [],
      count: 0,
      totalChargeAmount: 0,
      totalDenialAmount: 0,
      totalPaidAmount: 0,
    },
    drugAccRateDetailTable_data_secondPass: {
      secondPassBilledTabularArrSec: [],
      secondPass: [],
      secondPassStatus: [],
      count: 0,
      totalChargeAmount: 0,
      totalDenialAmount: 0,
      totalPaidAmount: 0,
    },

    // for handling filters Arr
    fillingIndicatorFilterArr: [],
    jCodesFilterArr: [],
    cptCodesFilterArr: [],
    payersFilterArr: [],
    IPRemarkCodesFilterArr: [],
    OPRemarkCodesFilterArr: [],

    // new filters
    filterLoader: {
      payers: true,
      serviceLine: true,
      claimStatus: true,
      reasoncode: true,
      facility: true,
      provider: true,
      fillingIndicator: true,
      ipRemarkCode: true,
      cptCode: true,
      groupReasonCode: true,
    },
    // below code for new logic for filters on 835 screen
    serviceLineFilterArr: [],
    cptCodesForFilterArr: [],
    groupReasonCodeFilterArr: [],
    claimStatusForFilterArr: [],
    payersForFilterArr: [],
    payeeForFilterArr: [],
    facilityForFilterArr: [],
    claimAdjReasonCodesForFilterArr: [],
    claimfillingIndiCodeForFilterArr: [],
    jCodesForFilterArr: [],
    IPRemarkCodesForFilterArr: [],
    OPRemarkCodesForFilterArr: [],
  },
  reducers: {
    setDenialsByCptScreen_data: (state, action) => {
      let data = action.payload;
      if (data?.length > -1) {
        state.denialsByCpt_dataArr.count = data[0]?.count;
        // state.denialsByCpt_dataArr.totalChargeAmount = data[0]?.totalChargeAmount;
        state.denialsByCpt_dataArr.output = data;
      } else {
        state.denialsByCpt_dataArr.count = null;
        // state.denialsByCpt_dataArr.totalChargeAmount = null;
        state.denialsByCpt_dataArr.output = [];
      }
    },

    setDrugAccRate_data: (state, action) => {
      let data = action.payload;
      state.drugAccRate_data.tabularArr = data;
    },
    setDrugAccRate_ServiceLine: (state, action) => {
      let data = action.payload;
      if (data?.departmentJson) {
        const oncology = JSON.parse(data?.departmentJson).filter(
          (item) => item.Level0Name == "Oncology"
        );
        const newOncology = oncology?.map((item) => {
          let value = {
            ...item,
            fullPath: [item],
          };
          return value;
        });
        state.drugAccRate_data.serviceLineFilterArr = oncology;
        state.drugAccRate_data.oncologyPreSelect = newOncology;
      } else {
        state.drugAccRate_data.oncologyPreSelect = [];
        state.drugAccRate_data.serviceLineFilterArr = [];
      }
      // state.drugAccRate_data.tabularArr = data;
    },

    setDrugAccRateDetailTable_data: (state, action) => {
      let data = action.payload;

      state.drugAccRateDetailTable_data = {
        firstPassBilledTabularArr: data?.output?.firstPassBilled,
        secondPassBilledTabularArr: data?.output?.secondPassBilled,
        count: data?.count,
        totalChargeAmount: data?.totalChargeAmount,
        totalDenialAmount: data?.totalDenialAmount,
        totalPaidAmount: data?.totalPaidAmount,
      };
    },
    setDrugAccRateDetailTable_data_secondPass: (state, action) => {
      let data = action.payload;

      state.drugAccRateDetailTable_data_secondPass = {
        secondPassBilledTabularArrSec: data,
        count: data?.count,
        totalChargeAmount: data?.totalChargeAmount,
        totalDenialAmount: data?.totalDenialAmount,
        totalPaidAmount: data?.totalPaidAmount,
      };
    },

    setDenialsByCptScreenFilters_data: (state, action) => {
      let data = action.payload;
      // for  FillingIndicatorCodeList filter Arr
      if (data?.FillingIndicatorCodeList?.length) {
        let newArr = [];
        data?.FillingIndicatorCodeList?.forEach((item) => {
          newArr.push({
            label: item?.description,
            value: item?.code,
          });
        });
        state.fillingIndicatorFilterArr = newArr;
      } else {
        state.fillingIndicatorFilterArr = [];
      }
      // for  JCodes filter Arr
      if (data?.JCodes?.length) {
        let newArr = [];
        data?.JCodes?.forEach((item) => {
          let userFriendlyName = item.description?.length
            ? `${item.cptCode} - ${item.description}`
            : `${item.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            count: item?.count,
            label: userFriendlyName,
          });
        });

        state.jCodesFilterArr = newArr;
      } else {
        state.jCodesFilterArr = [];
      }

      // for  cptCodes filter Arr
      if (data?.cptCodes?.length) {
        let newArr = [];
        data?.cptCodes?.forEach((item) => {
          let userFriendlyName = item?.description?.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            count: item?.count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.cptCodesFilterArr = descending;
      } else {
        state.cptCodesFilterArr = [];
      }

      // for  payers filter Arr
      if (data?.payers?.length) {
        let newArr = [];
        data?.payers?.forEach((item) => {
          newArr.push({
            label: item?.payer,
            value: item?.id,
          });
        });

        state.payersFilterArr = newArr;
      } else {
        state.payersFilterArr = [];
      }

      // for  IPRemarkCodes filter Arr
      if (data?.IPRemarkCodes?.length) {
        let newArr = [];
        data?.IPRemarkCodes?.forEach((item) => {
          let userFriendlyName = `${item?.ipRemarkCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.ipRemarkCode,
            label: userFriendlyName,
          });
        });

        state.IPRemarkCodesFilterArr = newArr;
      } else {
        state.IPRemarkCodesFilterArr = [];
      }

      // for  OPRemarkCodes filter Arr
      if (data?.OPRemarkCodes?.length) {
        let newArr = [];
        data?.OPRemarkCodes?.forEach((item) => {
          let userFriendlyName = `${item?.opRemarkCode}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.opRemarkCode,
            label: userFriendlyName,
          });
        });

        state.OPRemarkCodesFilterArr = newArr;
      } else {
        state.OPRemarkCodesFilterArr = [];
      }
    },

    //Service Line Filters
    gettingServiceLineFilter: (state, action) => {
      state.filterLoader.serviceLine = true;
    },
    successServiceLineFilter: (state, action) => {
      const data = action?.payload;

      if (data?.CPTCodeJSON) {
        state.serviceLineFilterArr = JSON.parse(data?.CPTCodeJSON);
      } else {
        state.serviceLineFilterArr = [];
      }
      state.filterLoader.serviceLine = false;
    },
    failedServiceLineFilter: (state, action) => {
      state.filterLoader.serviceLine = false;
    },

    //Payers Filters
    gettingPayerFilter: (state, action) => {
      state.filterLoader.payers = true;
    },
    successPayerFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          newArr.push({
            label: item?.name,
            value: item?.payerId,
          });
        });

        state.payersForFilterArr = newArr;
      } else {
        state.payersForFilterArr = [];
      }
      state.filterLoader.payers = false;
    },
    failedPayerFilter: (state, action) => {
      state.filterLoader.payers = false;
    },

    //Claim Status Code Filters
    gettingClaimStatusFilter: (state, action) => {
      state.filterLoader.claimStatus = true;
    },
    successClaimStatusFilter: (state, action) => {
      const data = action?.payload;

      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.claimStatusCodeId} - ${item?.description}`;
          newArr.push({
            value: item?.claimStatusCodeId,
            label: userFriendlyName,
          });
        });

        state.claimStatusForFilterArr = newArr;
      } else {
        state.claimStatusForFilterArr = [];
      }
      state.filterLoader.claimStatus = false;
    },
    failedClaimStatusFilter: (state, action) => {
      state.filterLoader.claimStatus = false;
    },
    //REASON CODE FILTER
    gettingReasonCodeFilter: (state, action) => {
      state.filterLoader.reasoncode = true;
    },
    successReasonCodeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.code} - ${item?.description}`;
          newArr.push({
            value: item?.code,
            label: userFriendlyName,
            count: item?.claimCount,
            // ServiceCount: item?.ServiceCount,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.claimAdjReasonCodesForFilterArr = descending;
      } else {
        state.claimAdjReasonCodesForFilterArr = [];
      }
      state.filterLoader.reasoncode = false;
    },
    failedReasonCodeFilter: (state, action) => {
      state.filterLoader.reasoncode = false;
    },
    //FACILITY FILTER
    gettingFacilityFilter: (state, action) => {
      state.filterLoader.facility = true;
    },
    successFacilityFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let facility_With_PrimaryId = `${item?.serviceFacilityName}`;
          newArr.push({
            label: facility_With_PrimaryId,
            value: item?.serviceFacilityId,
            // id: item.id,
          });
        });

        state.facilityForFilterArr = newArr;
      } else {
        state.facilityForFilterArr = [];
      }
      state.filterLoader.facility = false;
    },
    failedFacilityFilter: (state, action) => {
      state.filterLoader.facility = false;
      state.facilityForFilterArr = [];
    },
    //Provider FILTER
    gettingProviderFilter: (state, action) => {
      state.filterLoader.provider = true;
    },
    successProviderFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        // for  Payee filter Arr
        let newArr = [];
        data?.forEach((item) => {
          let payeeName_With_PrimaryId = `${item?.name} - ${item?.primaryId}`;
          newArr.push({
            label: payeeName_With_PrimaryId,
            value: item?.payeeId,
            // id: item.id,
          });
        });

        state.payeeForFilterArr = newArr;
      } else {
        state.payeeForFilterArr = [];
      }
      state.filterLoader.provider = false;
    },
    failedProviderFilter: (state, action) => {
      state.filterLoader.provider = false;
      state.payeeForFilterArr = [];
    },
    //Filling Indicator
    gettingFillingIndicatorFilter: (state, action) => {
      state.filterLoader.fillingIndicator = true;
    },
    successFillingIndicatorFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.description}`;
          newArr.push({
            value: item?.code,
            label: userFriendlyName,
          });
        });

        state.claimfillingIndiCodeForFilterArr = newArr;
      } else {
        state.claimfillingIndiCodeForFilterArr = [];
      }
      state.filterLoader.fillingIndicator = false;
    },
    failedFillingIndicatorFilter: (state, action) => {
      state.filterLoader.fillingIndicator = false;
      state.claimfillingIndiCodeForFilterArr = [];
    },
    //IP Remork Codes
    gettingIpRemarkCodeFilter: (state, action) => {
      state.filterLoader.ipRemarkCode = true;
    },
    successIpRemarkCodeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = `${item?.code}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          newArr.push({
            value: item?.code,
            label: userFriendlyName,
          });
        });

        state.IPRemarkCodesForFilterArr = newArr;
        state.OPRemarkCodesForFilterArr = newArr;
      } else {
        state.IPRemarkCodesForFilterArr = [];
        state.OPRemarkCodesForFilterArr = [];
      }
      state.filterLoader.ipRemarkCode = false;
    },
    failedIpRemarkCodeFilter: (state, action) => {
      state.filterLoader.ipRemarkCode = false;
      state.IPRemarkCodesForFilterArr = [];
    },
    //CPT CODES
    gettingCptCodeFilter: (state, action) => {
      state.filterLoader.cptCode = true;
    },
    successCptCodeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = item?.description.length
            ? `${item?.cptCode} - ${item?.description}`
            : `${item?.cptCode}`;
          newArr.push({
            value: item?.cptCode,
            // count: item?.count,
            label: userFriendlyName,
          });
        });

        let descending = newArr.sort(
          (a, b) => Number(b.count) - Number(a.count)
        );

        state.cptCodesForFilterArr = descending;
      } else {
        state.cptCodesForFilterArr = [];
      }
      state.filterLoader.cptCode = false;
    },
    failedCptCodeFilter: (state, action) => {
      state.filterLoader.cptCode = false;
      state.cptCodesForFilterArr = [];
    },
    //Group Reason Code
    gettingGroupReasonCodeFilter: (state, action) => {
      state.filterLoader.groupReasonCode = true;
    },
    successGroupReasonCodeFilter: (state, action) => {
      const data = action?.payload;
      if (data?.length) {
        let newArr = [];
        data?.forEach((item) => {
          let userFriendlyName = item?.description.length
            ? `${item?.groupCode} - ${item?.description}`
            : `${item?.groupCode}`;
          newArr.push({
            value: item?.groupCode,
            // count: item?.count,
            label: userFriendlyName,
          });
        });

        state.groupReasonCodeFilterArr = newArr;
      } else {
        state.groupReasonCodeFilterArr = [];
      }
      state.filterLoader.groupReasonCode = false;
    },
    failedGroupReasonCodeFilter: (state, action) => {
      state.filterLoader.groupReasonCode = false;
      state.groupReasonCodeFilterArr = [];
    },
  },
});

export const {
  setDenialsByCptScreen_data,
  setDrugAccRate_data,
  setDenialsByCptScreenFilters_data,
  setDrugAccRateDetailTable_data,
  setDrugAccRateDetailTable_data_secondPass,

  //filters

  //Payers
  gettingPayerFilter,
  successPayerFilter,
  failedPayerFilter,

  //Service Line
  gettingServiceLineFilter,
  successServiceLineFilter,
  failedServiceLineFilter,

  //Claim Status Code
  gettingClaimStatusFilter,
  successClaimStatusFilter,
  failedClaimStatusFilter,
  //Reason Code
  gettingReasonCodeFilter,
  successReasonCodeFilter,
  failedReasonCodeFilter,
  //Facility
  gettingFacilityFilter,
  successFacilityFilter,
  failedFacilityFilter,
  //Provider
  gettingProviderFilter,
  successProviderFilter,
  failedProviderFilter,
  //Filling Indicator
  gettingFillingIndicatorFilter,
  successFillingIndicatorFilter,
  failedFillingIndicatorFilter,
  //IP Remark Code
  gettingIpRemarkCodeFilter,
  successIpRemarkCodeFilter,
  failedIpRemarkCodeFilter,
  //CPT Code
  gettingCptCodeFilter,
  successCptCodeFilter,
  failedCptCodeFilter,
  //Group Code
  gettingGroupReasonCodeFilter,
  successGroupReasonCodeFilter,
  failedGroupReasonCodeFilter,
  setDrugAccRate_ServiceLine,
} = denialsByCptScreenSlice.actions;

// get DenialsByCptScreen
export const gettingNewDenialsByCptScreen_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "denialsByCptScreenFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "denialsByCptScreenFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
    }

    // let sliceStateForDate =
    //   getState().npReducers.filters.drugAccRateCommonFilter;
    let sliceStateUser = getState().pReducers.user;

    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.denialsByCptScreenFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      // dispatch(gettingDenialsByCptScreenFiltersData_Call());

      let paramsTemp = {
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicator
          ),
        }),
        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.IPRemarkCodes
          ),
        }),
        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(typeof sliceState?.search !== "undefined" &&
          sliceState?.search !== "" &&
          sliceState?.search !== null && {
            ServiceBilledAmount: sliceState?.search,
          }),
        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicator
          ),
        }),
        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.IPRemarkCodes
          ),
        }),
        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(typeof sliceState?.search !== "undefined" &&
          sliceState?.search !== "" &&
          sliceState?.search !== null && {
            ServiceBilledAmount: sliceState?.search,
          }),

        ...(sliceState?.cptCodes?.length > 0 && {
          cptCodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState?.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }
      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const payerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const reasonCodeParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const providerParams = {
        ...(sliceStateForDate.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };
      const drgCodeParams = {};

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDenialsByCptScreen_data,
        "denialsByCptScreenFilter",
        "GET_NEW_DENIALS_BY_CPT_SCREEN",
        true,
        "GET_ALL_FILTERS_DATA_DENIALS_BY_CPT_SCREEN",
        setDenialsByCptScreenFilters_data
      );

      // filters api call here
      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          {},
          {},
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: true,
            isFillingIndicatorAPI: true,
            isIPOPRemartCodeAPI: true,
            isCptCodeAPI: true,
            isClaimStatusAPI: false,
            isReasonCodeAPI: false,
            isProviderAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isGroupCodeAPI: false,
            isDrgAPI: false,
          }
        )
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get denial by cpt screen filters data
export const gettingDenialsByCptScreenFiltersData_Call =
  () => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.denialsByCptScreenFilter;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let params = {
        start,
        limit,
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicator
          ),
        }),
        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.IPRemarkCodes
          ),
        }),
        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.jCodes?.length > 0 && {
              CPTCode: getMultiSelectFilterIdForApi_ForId(sliceState?.jCodes),
            }
          : sliceState?.cptCodes?.length > 0 && {
              CPTCode: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
            }),
        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
            }
          : { type: "" }),

        ...(typeof sliceState?.search !== "undefined" &&
          sliceState?.search !== "" &&
          sliceState?.search !== null && {
            ServiceBilledAmount: sliceState?.search,
          }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
      };

      ///// API CALL //////

      const res = await genericAxiosCall(
        serverRoutes?.GET_ALL_FILTERS_DATA_DENIALS_BY_CPT_SCREEN,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////

      if (!res?.data?.success) {
        // toast.error(`Something went wrong`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(setDenialsByCptScreenFilters_data([]));
        dispatch(
          setListingState({
            listing: "denialsByCptScreenFilter",
            value: {
              loadingMore: "",
            },
          })
        );
      } else {
        dispatch(setDenialsByCptScreenFilters_data(res?.data?.data));
        dispatch(
          setListingState({
            listing: "denialsByCptScreenFilter",
            value: {
              // hasMore: res?.data?.data?.length === sliceState.pageSize,
              loadingMore: "",
            },
          })
        );
      }

      return 0;
    } catch (e) {
      // console.log("e--->>", e);

      dispatch(
        setListingState({
          listing: "denialsByCptScreenFilter",
          value: {
            initialLoading: false,
            loadingMore: "",
            hasMore: false,
          },
        })
      );
      dispatch(setDenialsByCptScreenFilters_data([]));

      return false;
    }
  };

// get One patient detail data
export const gettingOnePatientData_Call =
  (data) => async (dispatch, getState) => {
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.denialsByCptScreenFilter;
    // let start = sliceState?.pageNumber;
    let start = 0;
    let limit = 50;

    let CPTCode = data?.cptCode;
    let PatientId = data?.patientId;

    try {
      ///// API CALL //////
      let params = {
        start,
        limit,
        CPTCode,
        PatientId,
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
      };

      const res = await genericAxiosCall(
        serverRoutes?.GET_ONE_RECORD_NEW_DENIALS_BY_CPT_SCREEN,
        "get",
        "",
        params
      );

      ////// API CALL END //////////////
      return res?.data?.data;
    } catch (e) {
      // console.log("e--->>", e);

      return false;
    }
  };

// get Drug Acceptence Rate data here
export const gettingDrugAccRate_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "drugAccRateFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
      if (clear === "resetBtn") {
        dispatch(
          resetListingStateForResetButton({
            listing: "drugAccRateFilter",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
    }

    // insightsSreensCommonFilter

    // let sliceStateForDate =
    //   getState().npReducers.filters.drugAccRateCommonFilter;
    let sliceStateForDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceState = getState().npReducers.filters.drugAccRateFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;
    // drugAccRateFilterNoOfRecordInTable
    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.drugAccRateFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicator
          ),
        }),
        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.IPRemarkCodes
          ),
        }),
        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.OPRemarkCodes
          ),
        }),
        ...(sliceState?.cptCodes?.length > 0 && {
          jcodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),

        // ...(sliceState?.serviceLineTypeonCology == true
        //   ? sliceState?.jCodes?.length > 0 && {
        //       CPTCode: getMultiSelectFilterIdForApi_ForId(sliceState?.jCodes),
        //     }
        //   : sliceState?.cptCodes?.length > 0 && {
        //       CPTCode: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        //     }),
        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
            }
          : { type: "" }),

        // ...(typeof sliceState?.search !== "undefined" &&
        //   sliceState?.search !== "" &&
        //   sliceState?.search !== null && {
        //     jcodes: sliceState?.search,
        //   }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState?.payers?.length > 0 && {
          payers: getMultiSelectFilterIdForApi_ForId(sliceState?.payers),
        }),
        ...(sliceState?.filingIndicator?.length > 0 && {
          fillingIndicatorIds: getMultiSelectFilterIdForApi_ForId(
            sliceState?.filingIndicator
          ),
        }),
        ...(sliceState?.IPRemarkCodes?.length > 0 && {
          IPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.IPRemarkCodes
          ),
        }),
        ...(sliceState?.OPRemarkCodes?.length > 0 && {
          OPRemarkCode: getMultiSelectFilterIdForApi_ForId(
            sliceState?.OPRemarkCodes
          ),
        }),

        ...(sliceState?.cptCodes?.length > 0 && {
          jcodes: getMultiSelectFilterIdForApi_ForId(sliceState?.cptCodes),
        }),
        ...(sliceState?.serviceLineTypeonCology == true
          ? sliceState?.serviceLine?.length > 0 && {
              type: getMultiSelectFilterIdForApi_ForId(sliceState?.serviceLine),
            }
          : { type: "oncology" }),

        // ...(typeof sliceState?.search !== "undefined" &&
        //   sliceState?.search !== "" &&
        //   sliceState?.search !== null && {
        //     jcodes: sliceState?.search,
        //   }),

        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateForDate?.from !== "" && {
          from: sliceStateForDate.from,
          // from: "01/01/2021",
        }),
        ...(sliceStateForDate?.to !== "" && {
          to: sliceStateForDate.to,
          // to: "12/31/2021",
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceStateForDate?.clientId) &&
            sliceStateForDate?.clientId.length > 0
              ? sliceStateForDate?.clientId[0].value
              : sliceStateForDate?.clientId || getCurrentClientId(),
        }),
      };

      if (sliceState?.treeNodeServiceLine?.length > 0) {
        const treeSelected = getTreeNodeObj(sliceState.treeNodeServiceLine);
        if (treeSelected) {
          params = { ...params, ...treeSelected };
          paramsTemp = { ...paramsTemp, ...treeSelected };
        }
      }
      const cptParams = {
        CPTCode: "",
        start: 0,
        limit: 100,
        ...(sliceState?.cptCodes?.length > 0 && {
          selectedCpts: getMultiSelectFilterValueForApi(sliceState?.cptCodes),
        }),
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const payerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const reasonCodeParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const providerParams = {
        ...(!sliceState?.search &&
          sliceStateForDate.from !== "" && {
            from: sliceStateForDate.from,
          }),
        ...(!sliceState?.search &&
          sliceStateForDate.to !== "" && {
            to: sliceStateForDate.to,
          }),
      };
      const drgCodeParams = {};

      // console.log("params--->>", params);
      // console.log("sliceState.pageSize--->>", sliceState.pageSize);

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      await commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDrugAccRate_data,
        "drugAccRateFilter",
        "GET_DRUG_ACC_RATE_SCREEN",
        null
      );

      dispatch(
        getClaimAllFiltersData_Call(
          cptParams,
          payerParams,
          reasonCodeParams,
          providerParams,
          {},
          {},
          drgCodeParams,
          {
            isServiceLineAPI: true,
            isPayersAPI: false,
            isClaimStatusAPI: false,
            isReasonCodeAPI: false,
            isProviderAPI: false,
            isFillingIndicatorAPI: false,
            isBillingProviderAPI: false,
            isRenderingProviderAPI: false,
            isIPOPRemartCodeAPI: false,
            isCptCodeAPI: true,
            isGroupCodeAPI: false,
            isDrgAPI: false,
          }
        )
      );

      commonPagination(
        getState,
        "",
        "",
        dispatch,
        setDrugAccRate_ServiceLine,
        "serviceLineDrugAccRateFilter",
        "GET_SERVICE_LINES_LIST",
        null
      );
    } catch (e) {
      console.log("e--->>", e);
    }
  };

// get Drug Acceptence Rate detail table data here
export const gettingDrugAccRateDetailTable_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "drugAccRateFilterDetail",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
    }

    let sliceState = getState().npReducers.filters.drugAccRateFilterDetail;
    let sliceStateUser = getState().pReducers.user;
    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceState.selJcode !== "" && {
          jcodes: sliceState.selJcode,
        }),
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceState?.clientId) &&
            sliceState?.clientId.length > 0
              ? sliceState?.clientId[0].value
              : sliceState?.clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState.selJcode !== "" && {
          jcodes: sliceState.selJcode,
        }),
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceState?.clientId) &&
            sliceState?.clientId.length > 0
              ? sliceState?.clientId[0].value
              : sliceState?.clientId || getCurrentClientId(),
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDrugAccRateDetailTable_data,
        "drugAccRateFilterDetail",
        "GET_DRUG_ACC_RATE_DETAIL_SCREEN",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export const gettingDrugAccRateDetailTable_Call_Second_Pass =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      if (clear === true) {
        dispatch(
          resetListingState({
            listing: "drugAccRateFilterDetailSecondPass",
            ignore: ["claimStatus", "payers", "frequencyType", "search"],
          })
        );
      }
    }

    let sliceState =
      getState().npReducers.filters.drugAccRateFilterDetailSecondPass;
    let sliceStateUser = getState().pReducers.user;
    let { clientId } = getState().npReducers.filters.drugAccRateFilterDetail;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      console.log("gettingDrugAccRateDetailTable_Call_Second_Pass--->>");

      let paramsTemp = {
        ...(sliceState.selJcode !== "" && {
          jcodes: sliceState.selJcode,
        }),
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(clientId) && clientId.length > 0
              ? clientId[0].value
              : clientId || getCurrentClientId(),
        }),
      };

      let params = {
        start,
        limit,
        ...(sliceState.selJcode !== "" && {
          jcodes: sliceState.selJcode,
        }),
        ...(sliceState?.from !== "" && {
          from: sliceState.from,
        }),
        ...(sliceState?.to !== "" && {
          to: sliceState.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceStateUser?.loginUserData?.isRcm && {
          clientId:
            Array.isArray(sliceState?.clientId) &&
            sliceState?.clientId.length > 0
              ? sliceState?.clientId[0].value
              : sliceState?.clientId || getCurrentClientId(),
        }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setDrugAccRateDetailTable_data_secondPass,
        "drugAccRateFilterDetailSecondPass",
        "GET_DRUG_ACC_RATE_DETAIL_SCREEN_SECOND_PASS",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export default denialsByCptScreenSlice.reducer;
