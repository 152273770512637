import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import {
  // setListingState,
  resetListingState,
  setListingState,
} from "../filters/claimManagerFilters";
import {
  commonPagination,
  getCurrentClientId,
  getDatetoIsoString,
  getRandomString,
  // getDataForLineChart,
} from "../../../components/common/Utils/utils";
// import { getMultiSelectFilterValueForApi } from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";
// import axios from "../../../AxiosConfig/axios";
import { toast } from "react-toastify";
import browserRoute from "../../../constants/browserRoutes";
import { getNextWeekMonQuarterlyDate } from "./../../../components/common/Utils/utils";

let constDate = null;

// carcHandleYearOverYear
const carcHandleYearOverYear = (data) => {
  let resData = data.oncologyCARCYearByYear;

  try {
    if (constDate === "YOY") {
      let allDate = {};
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // data.oncologyCARCYearByYear
      const uniqueYear = [...new Set(resData.map((item) => item.year))];

      for (let i = 0; i < uniqueYear.length; i++) {
        let arr = [];
        let year = uniqueYear[i];

        resData.map((item, index) => {
          if (item?.year === year) {
            arr.push(item);
          }
        });

        allDate[`${year}`] = arr;
      }

      let newObj = {};
      for (let i = 0; i < uniqueYear.length; i++) {
        let newArr = [];
        let j = 0;
        let year = uniqueYear[i];
        let tempArr = allDate[`${year}`];
        monthNames.map((month, index) => {
          if (j < tempArr?.length) {
            if (month === tempArr[j]?.monthName && j < tempArr.length) {
              newArr.push(tempArr[j]);
              j++;
            } else {
              let t = {
                monthName: month,
                year: uniqueYear[i],
                totalAmount: 0,
                denialAmount: 0,
                denialCount: 0,
              };
              newArr.push(t);
            }
          } else {
            let t = {
              monthName: month,
              year: uniqueYear[i],
              totalAmount: 0,
              denialAmount: 0,
              denialCount: 0,
            };
            newArr.push(t);
          }
        });
        newObj[`${year}`] = newArr;
      }
      //update from here
      allDate = newObj;
      return { allDate, uniqueYear };
    } else {
      return false;
    }
  } catch (e) {
    // console.log("e--->>", e);

    return false;
  }
};

export const GoalTackingSlice = createSlice({
  name: "GoalTackingSlice",
  initialState: {
    //Filter Array

    goalTrackingData: [],
    reasonCodes: [],
    jCodes: [],
    denialCount: 0,
    denialPercentage: 0,
    goalHistoryData: {},
  },
  reducers: {
    setGoalTracker_data: (state, action) => {
      let data = action.payload;
      state.goalTrackingData = data;
    },
    setGoalHistory_data: (state, action) => {
      let data = action.payload;
      state.goalHistoryData = data;
    },
    setCode_data: (state, action) => {
      let data = action.payload?.data;

      if (data?.cptCode?.length > 0) {
        let jCodes = data?.cptCode?.map((item, index) => {
          let desc = `${item?.code}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          return {
            value: item?.code,
            label: desc,
          };
        });
        state.jCodes = jCodes;
      } else {
        state.jCodes = [];
      }
      if (data?.reasonCodes) {
        let reasonCodes = data?.reasonCodes?.map((item, index) => {
          let desc = `${item?.code}${
            item?.description ? ` - ${item?.description}` : ""
          }`;
          return {
            value: item?.code,
            label: desc,
          };
        });
        state.reasonCodes = reasonCodes;
      } else {
        state.reasonCodes = [];
      }
    },
  },
});

export const { setGoalTracker_data, setCode_data, setGoalHistory_data } =
  GoalTackingSlice.actions;

//
export const gettingAllCodes =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "goalTrackingFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }
    dispatch(
      setListingState({
        listing: "goalTrackingFilter",
        value: {
          loadingMore2: "data-loading",
          loadingFilters: "",
        },
      })
    );
    //Get Reason Code and Jcodes List

    try {
      genericAxiosCall(serverRoutes.GET_ALL_CODES, "get", "", "")
        .then((res) => {
          if (res?.data?.success) {
            dispatch(setCode_data(res?.data));
            dispatch(
              setListingState({
                listing: "goalTrackingFilter",
                value: {
                  loadingMore2: "",
                },
              })
            );
          } else {
            dispatch(setCode_data([]));
            dispatch(
              setListingState({
                listing: "goalTrackingFilter",
                value: {
                  loadingMore2: "",
                },
              })
            );
          }
        })
        .catch((error) => {
          dispatch(setCode_data([]));
          dispatch(
            setListingState({
              listing: "goalTrackingFilter",
              value: {
                loadingMore2: "",
              },
            })
          );
        });
    } catch (e) {
      // console.log("e--->>", e);
    }
  };
export const calculateDenialCountTrigger =
  (
    { reasonCodes, jCodes, frequencyType, filter, to, from, goalId, clientId },
    setDenialPer,
    setDenialAmt,
    setCount,
    setCalculatingLoader,
    setIsCalculationDone
  ) =>
  async (dispatch, getState) => {
    let sliceStateUser = getState().pReducers.user;

    let params = {
      reasonCode: reasonCodes ? reasonCodes : null,
      cptCode: jCodes ? jCodes : null,
      frequency: frequencyType.slice(0, 1).toLocaleLowerCase(),
      filter: filter,
      to:
        // getDatetoIsoString(to)
        moment(to).format("YYYY-MM-DD"),
      from:
        // getDatetoIsoString(from)
        moment(from).format("YYYY-MM-DD"),
      trackerId: goalId,
      // clientId here
      ...(sliceStateUser?.loginUserData?.isRcm && {
        clientId: clientId,
      }),
    };

    //Get Reason Code and Jcodes List
    setCalculatingLoader(true);
    try {
      // genericAxiosCall(serverRoutes.CALCULATE_DENIAL_COUNT, "get", "", params)
      genericAxiosCall(
        // `${serverRoutes.CALCULATE_DENIAL_COUNT}reasonCode=${params?.reasonCode}&cptCode=${params?.cptCode}&frequency=${params?.frequency}&filter=${params?.filter}&to=${params?.to}&from=${params?.from}&trackerId=${params?.trackerId}&clientId=${params?.clientId}`,
        `${serverRoutes.CALCULATE_DENIAL_COUNT}`,
        "get",
        "",
        params
      )
        .then((res) => {
          if (res?.data?.success) {
            let data = res?.data?.data;
            if (data?.denialPercentage !== null) {
              setDenialPer(data?.denialPercentage);
              setDenialAmt(data?.baseLineAmount);
            } else {
              setDenialPer(0);
              setDenialAmt(0);
            }
            if (data?.denialCount !== null) {
              setCount(data?.denialCount);
            } else {
              setCount(0);
            }
            setCalculatingLoader(false);
          } else {
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            setCalculatingLoader(false);
          }

          setIsCalculationDone && setIsCalculationDone(true);
        })
        .catch((err) => {
          setCalculatingLoader(false);
          // toast.error(`Something went wrong`, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
        });
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export const gettingGoalTrackingAllData =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "goalTrackingFilter",
          ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }
    let sliceState = getState().npReducers.filters.goalTrackingFilter;
    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let sliceStateUser = getState().pReducers.user;

    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.goalTrackingFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    let paramsTemp = {
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.order !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      ...(sliceState?.fetchLatest === true && { timestamp: Date.now() }),
      ...(sliceStateUser?.loginUserData?.isRcm && {
        clientId:
          Array.isArray(sliceStateForClientId?.clientId) &&
          sliceStateForClientId?.clientId.length > 0
            ? sliceStateForClientId?.clientId[0].value
            : sliceStateForClientId?.clientId || getCurrentClientId(),
      }),
    };

    let params = {
      start,
      limit,
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.order !== "" && {
        OrderBy: sliceState.orderBy,
      }),
      ...(sliceStateUser?.loginUserData?.isRcm && {
        clientId:
          Array.isArray(sliceStateForClientId?.clientId) &&
          sliceStateForClientId?.clientId.length > 0
            ? sliceStateForClientId?.clientId[0].value
            : sliceStateForClientId?.clientId || getCurrentClientId(),
      }),
    };

    try {
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setGoalTracker_data,
        "goalTrackingFilter",
        "GET_GOAL_TRACKER_DATA",
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// get single goal current Denial rate
export const gettingSingleGoalCurrentDenialRate =
  (goalId, filter, from, to, setLoadingState, setGuageChartValue) =>
  async (dispatch, getState) => {
    try {
      setLoadingState(true);
      genericAxiosCall(
        `${serverRoutes.GET_SINGLE_GOAL_CURRENT_DENIAL_RATE_BY_ID}${goalId}&filter=${filter}&from=${from}&to=${to}`,
        "get",
        "",
        ""
      )
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;

            setGuageChartValue(res?.data?.data?.denialPercentage);
          } else {
            setLoadingState(false);

            // dispatch(setCode_data([]));
          }
        })
        .catch((error) => {
          // dispatch(setCode_data([]));
          setLoadingState(false);
        });
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

// gettingSingleGoalGuageChartValue
export const gettingSingleGoalGuageChartValue =
  (goalId, setLoadingState, setGuageChartValue) =>
  async (dispatch, getState) => {
    try {
      setLoadingState(true);
      genericAxiosCall(
        `${serverRoutes.GET_SINGLE_GOAL_DENIAL_RATE_BY_ID}${goalId}`,
        "get",
        "",
        ""
      )
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;

            setGuageChartValue(data);
          } else {
            setLoadingState(false);

            // dispatch(setCode_data([]));
          }
        })
        .catch((error) => {
          // dispatch(setCode_data([]));
          setLoadingState(false);
        });
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export const gettingGoalTrackingDataById =
  (
    goalId,
    setLoadingState,
    setInputs,
    setStartDate,
    setEndDate,
    setState,
    setEndDate2,
    setStartingFromDate,
    setRawData
  ) =>
  async (dispatch, getState) => {
    let sliceState =
      getState().npReducers.filters.carcTrendsDashboardDetailFilter;

    let sliceStateForFilter = getState().npReducers.filters.goalTrackingFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    //Get Reason Code and Jcodes List

    try {
      genericAxiosCall(
        `${serverRoutes.GET_GOAL_TRACKER_DATA_BY_ID}/${goalId}`,
        "get",
        "",
        ""
      )
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;
            setRawData(data);
            let tempData = {
              // name: data?.goalName,
              // reasonCode : reasonCode?.split(","),
              // frequencyTypeJcode: data?.cptCode,
              // frequencyTypeReasonCode: data?.reasonCode,
              // denialPercentage: data?.denialPer,
              name: data?.goalName,
              calculateBy: data?.dateFilter,
              clientId: data?.clientId,

              // nextRun: data?.frequency,
              baseLine: data?.baseLine,
              baseTo: data?.baseTo,
              baseFrom: data?.baseFrom,
              denialCount: data?.denialCount,
              // reasonCode: data?.reasonCode ? data?.reasonCode.split(",") : [],
              reasonCode: data?.reasonCode,
              // denialPer: data?.66,
              target: data?.targetPer,
              // jCode: data?.cptCode ? data?.cptCode.split(",") : [],
              jCode: data?.cptCode,
              frequencyType: data?.frequency,
              // isNotify: data?.isNotify,
              status: data?.goalStatus,
              tenureBy: data?.calucationFilter === "a" ? "All" : "f",
            };
            // setStartDate(new Date(data?.startedOn));

            setStartDate(new Date());
            setEndDate(new Date(data?.endOn));
            setEndDate2(new Date(data?.from));
            setStartingFromDate(data?.from);
            setInputs(tempData);
            setLoadingState(false);
            setState({
              isNotify: data?.isNotify,
            });

            // dispatch(setCode_data(res?.data));
          } else {
            setLoadingState(false);

            // dispatch(setCode_data([]));
          }
        })
        .catch((error) => {
          // dispatch(setCode_data([]));
          setLoadingState(false);
        });
    } catch (e) {
      // console.log("e--->>", e);
    }
  };

export const createNewGoalTracking =
  (
    {
      name,
      jCode,
      reasonCode,
      startDate,
      endDate,
      denialPercentage,
      target,
      calculateBy,
      frequencyType,
      status,
      tenureBy,
      nextRun,
      clientId,
    },
    denialPer,
    count,
    calculateDate,
    setAddNewUserModal = {},
    state,
    startingFromDate,
    endDate2,
    // history = {},
    handleGoalNewModalClose
  ) =>
  async (dispatch, getState) => {
    setListingState({
      listing: "goalTrackingFilter",
      value: {
        loadingMore3: "data-loading",
      },
    });

    let params = {
      // GoalTrackerId: null,
      goalName: name ? name : "",

      type: "on cology",

      reasonCode: reasonCode ? reasonCode.toString() : "",
      clientId: clientId ? clientId.toString() : "",
      startedOn: startDate,
      endOn: endDate,
      denialPer: denialPer,
      denialCount: count,
      dateFilter: calculateBy,
      baseline: denialPer,
      targetPer: target,
      cptCode: jCode ? jCode.toString() : "",
      frequency: calculateBy,
      calucationFilter: tenureBy === "All" ? "a" : "f",
      isNotify: state?.isNotify,
      goalStatus: status,
      nextRun: getNextWeekMonQuarterlyDate(calculateBy),
      lastRun: new Date(),
      from: moment(startingFromDate).format("YYYY-MM-DD"),
      to: getDatetoIsoString(endDate2),
      startedOn: new Date(),
      // Interval: frequencyType ? frequencyType.toLocaleLowerCase() : null,
    };

    try {
      genericAxiosCall(
        serverRoutes.CREATE_NEW_GOAL_TRACKER,
        "post",
        params,
        ""
      ).then((res) => {
        setListingState({
          listing: "goalTrackingFilter",
          value: {
            loadingMore3: "",
          },
        });
        setAddNewUserModal(false);
        // toast.success(`Goal Created Successfully.`, {
        //   position: toast.POSITION.TOP_CENTER,
        // });

        if (res?.data?.success) {
          toast.success(
            res?.data?.message
              ? res?.data?.message
              : "Goal Created Successfully",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          dispatch(
            setListingState({
              listing: "goalTrackingFilter",
              value: {
                fetchLatest: true,
              },
            })
          );
        } else {
          toast.error(
            res?.data?.message ? res?.data?.message : "Something went wrong!",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }

        if (res?.data?.success === true) {
          handleGoalNewModalClose();
          dispatch(gettingGoalTrackingAllData());
        }
        // dispatch(
        //   resetListingState({
        //     listing: "goalTrackingFilter",
        //     ignore: ["claimStatus", "payers", "frequencyType", "search"],
        //   })
        // );
        dispatch(
          setListingState({
            listing: "goalTrackingFilter",
            value: {
              orderBy: `${getRandomString()}`,
              // orderBy: `CreatedOn`,
              order: "desc",
            },
          })
        );
        dispatch(
          setListingState({
            listing: "goalTrackingFilter",
            value: {
              // orderBy: `${getRandomString()}`,
              orderBy: `CreatedOn`,
              order: "desc",
            },
          })
        );
        // history.push(browserRoute?.GOALS_TRAKING);
      });
    } catch (e) {
      setListingState({
        listing: "goalTrackingFilter",
        value: {
          loadingMore3: "",
        },
      });
    }
  };

export const editGoalTracking =
  (
    {
      name,
      jCode,
      reasonCode,
      startDate,
      endDate,
      denialPercentage,
      target,
      baseLine,
      frequencyType,
      status,
      goalId,
      tenureBy,
      nextRun,
      isNotify,
      calculateBy,
      clientId,
    },
    denialPer,
    state,
    calculateDate,
    history = {},
    handleGoalUpdateModalClose
  ) =>
  async (dispatch, getState) => {
    setListingState({
      listing: "goalTrackingFilter",
      value: {
        loadingMore4: "data-loading",
      },
    });
    let params = {
      GoalTrackerId: goalId,

      goalName: name ? name : "",
      clientId: clientId ? clientId.toString() : "",

      type: "on cology",

      reasonCode: reasonCode ? reasonCode.toString() : "",
      startedOn: startDate,
      endOn: endDate,
      denialPer: denialPer,
      baseLine: baseLine,
      dateFilter: calculateBy,
      targetPer: target,
      cptCode: jCode ? jCode.toString() : "",
      frequency: calculateBy,
      calucationFilter: tenureBy === "All" ? "f" : tenureBy,
      isNotify: state?.isNotify,
      goalStatus: status,
      nextRun: getNextWeekMonQuarterlyDate(calculateBy),
      lastRun: new Date(),
      // Interval: frequencyType ? frequencyType.toLocaleLowerCase() : null,
    };

    try {
      genericAxiosCall(
        serverRoutes.CREATE_NEW_GOAL_TRACKER,
        "put",
        params,
        ""
      ).then((res) => {
        setListingState({
          listing: "goalTrackingFilter",
          value: {
            loadingMore4: "",
          },
        });

        toast.success(`Edited Successfully.`, {
          position: toast.POSITION.TOP_CENTER,
        });

        dispatch(
          setListingState({
            listing: "goalTrackingFilter",
            value: {
              orderBy: `CreatedOn`,
              order: "desc",
            },
          })
        );
        dispatch(gettingGoalTrackingAllData());

        handleGoalUpdateModalClose();
        // dispatch(
        //   resetListingState({
        //     listing: "goalTrackingFilter",
        //     ignore: ["claimStatus", "payers", "frequencyType", "search"],
        //   })
        // );

        // history.push(browserRoute?.GOALS_TRAKING);
      });
    } catch (e) {
      // console.log("e--->>", e);
      toast.success(`Edited Goal Failed.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      setListingState({
        listing: "goalTrackingFilter",
        value: {
          loadingMore4: "",
        },
      });
    }
  };

export const deleteGoal =
  (goalId, setAddNewUserModal, setDeleting) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.goalTrackingFilter;
    const pageNumber = sliceState.pageNumber;
    const pageSize = sliceState.pageSize;
    setListingState({
      listing: "goalTrackingFilter",
      value: {
        loadingMore: "data-loading",
        loadingFilters: "data-loading",
      },
    });

    setDeleting(true);
    try {
      genericAxiosCall(
        `${serverRoutes.GET_GOAL_TRACKER_DATA_BY_ID}/${goalId}`,
        "delete",
        "",
        ""
      ).then((res) => {
        if (res?.data?.success) {
          setListingState({
            listing: "goalTrackingFilter",
            value: {
              loadingMore: "",
              loadingFilters: "",
            },
          });
          setDeleting(false);
          setAddNewUserModal(false);
          // toast.success(`Deleted Successfully.`, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          toast.success(
            res?.data?.message ? res?.data?.message : "Deleted Successfully!",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
          dispatch(
            resetListingState({
              listing: "goalTrackingFilter",
              ignore: ["claimStatus", "payers", "frequencyType", "search"],
            })
          );
          setListingState({
            listing: "goalTrackingFilter",
            value: {
              pageNumber: pageNumber,
              pageSize: pageSize,
            },
          });
          dispatch(gettingGoalTrackingAllData());
        } else {
          setListingState({
            listing: "goalTrackingFilter",
            value: {
              loadingMore: "",
              loadingFilters: "",
            },
          });
          setDeleting(false);
          setAddNewUserModal(false);
          toast.success(`Deleted Failed.`, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } catch (e) {
      setListingState({
        listing: "goalTrackingFilter",
        value: {
          loadingMore: "",
          loadingFilters: "",
        },
      });
    }
  };

export const gettingGoalHistoryById =
  (goalId, setLoadingClass) => async (dispatch, getState) => {
    let sliceState = getState().npReducers.filters.goalTrackingHistoryFilter;

    let start = sliceState.pageNumber;
    let limit = sliceState.pageSize;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      // start = rec_val + 1;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }
    let paramsTemp = {
      ...{ trackerId: goalId },
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.order !== "" && {
        OrderBy: sliceState.orderBy,
      }),
    };
    let params = {
      ...{ trackerId: goalId },
      start,
      limit,
      ...(sliceState.order !== "" && {
        Order: sliceState.order,
      }),
      ...(sliceState.order !== "" && {
        OrderBy: sliceState.orderBy,
      }),
    };

    try {
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setGoalHistory_data,
        "goalTrackingHistoryFilter",
        `GET_SINGLE_GOAL_HISTORY_BY_ID`,
        null
      );
    } catch (e) {
      // console.log("e--->>", e);
    }

    // try {
    //   setLoadingClass("loading");
    //   genericAxiosCall(
    //     `${serverRoutes.GET_SINGLE_GOAL_HISTORY_BY_ID}${goalId}&start=${sliceState?.start}&limit=${sliceState?.limit}&order=${sliceState?.order}&orderBy=${sliceState?.orderBy}`,
    //     "get",
    //     "",
    //     ""
    //   )
    //     .then((res) => {
    //       if (res?.data?.success) {
    //         let obj = {
    //           count: res?.data?.data?.count,
    //           goalDetails: res?.data?.data?.goalDetails,
    //         };
    //         setHistoryArr(obj);
    //       } else {
    //         setHistoryArr({ count: 0, goalDetails: [] });
    //       }
    //       setLoadingClass("");
    //     })
    //     .catch((error) => {
    //       setHistoryArr({ count: 0, goalDetails: [] });
    //       setLoadingClass("");
    //     });
    // } catch (e) {
    //   // console.log("e--->>", e);
    //   setHistoryArr({ count: 0, goalDetails: [] });

    //   setLoadingClass("");
    // }
  };

export default GoalTackingSlice.reducer;
