import { createSlice } from "@reduxjs/toolkit";
import { resetListingState } from "../filters/claimManagerFilters";
import {
  commonPagination,
  downloadFileFromApiResponse,
} from "../../../components/common/Utils/utils";
import genericAxiosCall from "../../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../../constants/serverRoutes";

export const FilesIntegrationSlice = createSlice({
  name: "FilesIntegrationSlice",
  initialState: {
    filesIntegrationData: [],
  },
  reducers: {
    setFilesIntegrationTabular_dataAction: (state, action) => {
      let data = action.payload;
      if (data?.length) {
        state.filesIntegrationData = data;
      } else {
        state.filesIntegrationData = [];
      }
    },
  },
});

export const { setFilesIntegrationTabular_dataAction } =
  FilesIntegrationSlice.actions;

// get  list
export const gettingFilesIntegrationTabularData_Call =
  ({ clear = false } = {}) =>
  async (dispatch, getState) => {
    if (clear) {
      dispatch(
        resetListingState({
          listing: "filesIntegrationFilter",
          //   ignore: ["claimStatus", "payers", "frequencyType", "search"],
        })
      );
    }

    let sliceStateUser = getState().pReducers.user;

    let sliceStateForClientId =
      getState().npReducers.filters.insightsSreensCommonFilter;

    let sliceState = getState().npReducers.filters.filesIntegrationFilter;
    let sliceStateforDate =
      getState().npReducers.filters.insightsSreensCommonFilter;
    let start = sliceState.pageNumber;
    // let limit = sliceState.pageSize;

    let limit =
      sliceStateUser?.globalObjNoOfRecordInTable
        ?.filesIntegrationFilterNoOfRecordInTable;

    if (start >= 2) {
      let rec_val = (start - 1) * limit;
      start = rec_val;
    }
    if (start == 1) {
      start = 0;
    }

    try {
      let paramsTemp = {
        ...(sliceStateforDate?.from !== "" && {
          from: sliceStateforDate.from,
        }),
        ...(sliceStateforDate?.to !== "" && {
          to: sliceStateforDate.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),
        ...(sliceState.viewFilter !== "" && {
          logType: sliceState.viewFilter,
        }),

        // ...(sliceStateUser?.loginUserData?.isRcm && {
        //   clientId:
        //     Array.isArray(sliceStateForClientId?.clientId) &&
        //     sliceStateForClientId?.clientId.length > 0
        //       ? sliceStateForClientId?.clientId[0].value
        //       : sliceStateForClientId?.clientId || getCurrentClientId(),
        // }),
      };
      let params = {
        start,
        limit,
        ...(sliceStateforDate?.from !== "" && {
          from: sliceStateforDate.from,
        }),
        ...(sliceStateforDate?.to !== "" && {
          to: sliceStateforDate.to,
        }),
        ...(sliceState.order !== "" && {
          Order: sliceState.order,
        }),
        ...(sliceState.order !== "" && {
          OrderBy: sliceState.orderBy,
        }),

        ...(sliceState.viewFilter !== "" && {
          logType: sliceState.viewFilter,
        }),

        // ...(sliceStateUser?.loginUserData?.isRcm && {
        //   clientId:
        //     Array.isArray(sliceStateForClientId?.clientId) &&
        //     sliceStateForClientId?.clientId.length > 0
        //       ? sliceStateForClientId?.clientId[0].value
        //       : sliceStateForClientId?.clientId || getCurrentClientId(),
        // }),
      };

      // Function(getState,params, dispatch,stateValueSetter, listingAction, apiURL)
      commonPagination(
        getState,
        paramsTemp,
        params,
        dispatch,
        setFilesIntegrationTabular_dataAction,
        "filesIntegrationFilter",
        "GET_ALL_FILES_INTEGRATION_DATA",
        null
      );
    } catch (e) {
      //   console.log("e--->>", e);
    }
  };

// get  download
export const gettingDownloadFile_Call =
  (fileId) => async (dispatch, getState) => {
    try {
      let params = {
        id: fileId,
      };

      const res = await genericAxiosCall(
        `${serverRoutes?.DOWNLOAD_FILE_FILE_INTEGRATION}`,
        "get",
        "",
        params,
        { responseType: "blob" } // Ensure the response is treated as a blob
      );

      downloadFileFromApiResponse(res?.data);
      return 0;
    } catch (e) {
      console.log("e--->>", e);
    }
  };

export default FilesIntegrationSlice.reducer;
