import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import AppImage from "../../../assets/images/app-luncher.svg";
import AppImageGreen from "../../../assets/images/app-luncher-green.svg";
// import DRAvaChatIcon from "../../../assets/svgs/DRAvaChatIcon";
// import DRAppealIcon from "../../../assets/svgs/DRAppealIcon";
// import DRDenial360Icon from "../../../assets/svgs/DRDenial360Icon";
import PolicyBotImage from "../../../assets/images/PolicyBot.svg";
import Denial360Image from "../../../assets/images/Denial360.svg";
import SmartImage from "../../../assets/images/SmartAppeal.svg";

import {
  getterAllBoughtSubscriptionModules,
  getterRoleBasedModules,
  moduleRolesEnum, // Ensure this is correctly imported
  subscriptionPlanNameKey,
} from "../Utils/utils";
import browserRoute from "../../../constants/browserRoutes";
import { COPILOT_NAME } from "../Utils/copilot";

const modulesString = [
  {
    label: "Denials 360",
    value: "Denials",
    // icon: <DRDenial360Icon />,
    // icon: <img src={Denial360Image} width="40px" />,
    icon: (
      <img
        src="https://res.cloudinary.com/dcb0ivmhq/image/upload/v1721819803/bot_p2ausu.svg"
        width="40px"
      />
    ),
    wrapper: moduleRolesEnum?.Denials, // Use after import
    navLocation: browserRoute?.DENIALS_360_HOME,
  },
  {
    label: "Smart Appeals",
    value: "Appeals",
    // icon: <DRAppealIcon />,
    icon: <img src={SmartImage} width="40px" />,
    wrapper: moduleRolesEnum?.Appeals, // Use after import
    navLocation: browserRoute?.SMART_APPEAL_HOME,
  },
  {
    label: COPILOT_NAME,
    value: "Policy",
    // icon: <DRAvaChatIcon />,
    icon: (
      <img
        src="https://res.cloudinary.com/dcb0ivmhq/image/upload/v1721819803/hourglass_pgipbd.svg"
        width="40px"
      />
    ),
    // icon: <img src={PolicyBotImage} width="40px" />,
    wrapper: moduleRolesEnum?.Policy, // Use after import
    navLocation: browserRoute?.POLICY_ADVISOR_HOME,
  },
];

function ModuleWrapper({ type, children }) {
  const userRoles = getterRoleBasedModules();
  const denialsRole = getterAllBoughtSubscriptionModules();

  const checkRoles = (moduleValue) =>
    denialsRole?.find(
      (role) =>
        [moduleRolesEnum[moduleValue]]?.includes(
          role?.[subscriptionPlanNameKey]
        ) &&
        userRoles?.find((role) =>
          [moduleRolesEnum[moduleValue]]?.includes(
            role?.[subscriptionPlanNameKey]
          )
        )
    );

  return checkRoles(type) || !type ? children : null;
}

export default function AppLunchar({
  currentModule,
  setCurrentModule,
  handleSelectModule,
  isWhiteLogo,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const nav = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (item) => {
    // console.log("handleSelect--->>", item);
    handleSelectModule(item);
    handleClose();

    nav(item?.navLocation);

    // Navigate to the new location without reloading the page
    // window.history.pushState({}, "", item?.navLocation);
    // Optionally, you may want to trigger any custom state updates or re-render if needed
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ padding: "0px", minWidth: "auto" }}
      >
        {/* <img src={AppImage} width="18px" /> */}
        <img src={!isWhiteLogo ? AppImage : AppImageGreen} width="18px" />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="DR-App-Lunchar-drop"
      >
        {modulesString?.map((item, index) => {
          return (
            <ModuleWrapper key={index} type={item.wrapper}>
              <MenuItem
                onClick={() => {
                  handleSelect(item);
                }}
                style={{
                  backgroundColor:
                    currentModule?.value === item?.value ? "#f2f2f2" : "",
                }}
              >
                <span className="DR-app-luncher-icon">
                  {item?.icon} {item?.label}
                </span>
              </MenuItem>
            </ModuleWrapper>
          );
        })}
      </Menu>
    </div>
  );
}
